import React from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import * as firebase from "firebase/app";
import "firebase/auth";
import Sign from "../../assets/sign.png";

import { Snack } from "../../Components/SnackbarMessage";

import { saveUserToken } from "../../actions/userActions";

import { useDispatch } from "react-redux";
import { StyledTextField, CenteredBox } from "./styles";

export function FirebaseLogin({ history }) {
  const [showPlain, setShowPlain] = React.useState(false);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const dispatch = useDispatch();

  function handleEmail(e) {
    setEmail(e.target.value);
  }

  function handlePassword(e) {
    setPassword(e.target.value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        dispatch(saveUserToken(res.user.toJSON().stsTokenManager.accessToken));
        history.push("/login");
      })
      .catch(() => {
        setSeverity("error");
        setMessage("You do not have access product. Sorry 'bout that!");
        setSnackOpen(true);
      });
  }

  function handleSnackClose() {
    setSnackOpen(false);
  }

  return (
    <>
      <CssBaseline />
      <Container maxWidth="xl">
        <Typography
          component="div"
          style={{
            backgroundColor: "#fff4b4",
            height: "100vh",
            width: "100vw",
          }}
        >
          <CenteredBox>
            <img
              src={Sign}
              style={{ width: "250px", marginBottom: "50px" }}
              alt="rova-reader-sign"
            />
            <form onSubmit={handleSubmit}>
              <StyledTextField
                id="email"
                value={email}
                onChange={handleEmail}
                label="Email"
              />
              <StyledTextField
                type={showPlain ? "text" : "password"}
                id="password"
                value={password}
                onChange={handlePassword}
                label="Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="start"
                      onClick={() => setShowPlain(!showPlain)}
                      style={{ cursor: "pointer" }}
                    >
                      {showPlain ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </InputAdornment>
                  ),
                }}
              />
              <Button color="primary" type="submit">
                Sign in
              </Button>
            </form>
          </CenteredBox>
        </Typography>
      </Container>
      <Snack
        open={snackOpen}
        severity={severity}
        message={message}
        handleClose={handleSnackClose}
      />
    </>
  );
}
