import styled, { css } from "styled-components";

export const H1 = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 22px;
  font-weight: bold;
  ${props =>
    props.color &&
    css`
      color: ${props => props.theme[props.color]};
    `};
`;
