import {
  SELECT_BG_COLOR,
  SELECT_TEXT_COLOR,
  COLOR_FILTER,
  STYLE_FILTER,
  SIZE_FILTER,
  SHOW_FILTER,
  HIDE_FILTER,
  GET_PREFS,
  SELECT_BG_COLOR_ALPHA,
  SPACING_OPTION,
  SPACING_FILTER,
  LAST_FILTER,
  SAVE_STORY_PAGE,
  SHOW_FILTERS,
  CLEAR,
  SET_LINE_HEIGHT,
  SET_LETTER_SPACING,
} from "../actions";

const initialState = {
  currentFilter: "all",
  colors: {
    selected: [],
    backgroundColor: "white",
    frequency: "",
    backgroundAlpha: "rgb(255,255,255)",
  },
  styles: {
    selected: [],
    frequency: "",
  },
  sizes: {
    selected: [],
    frequency: "",
  },
  library: [],
  spacing: { spacingOption: "word", lineHeight: 30, letterSpacing: 2 },
  lastFilter: { filter: "" },
  showFilters: false,
};

export function readerReducer(state = initialState, action) {
  switch (action.type) {
    case SPACING_OPTION:
      return {
        ...state,
        spacing: { ...state.spacing, spacingOption: action.option },
      };
    case SELECT_BG_COLOR_ALPHA:
      return {
        ...state,
        colors: {
          ...state.colors,
          backgroundAlpha: action.backgroundAlpha.newColor,
        },
      };
    case SELECT_BG_COLOR:
      return {
        ...state,
        colors: { ...state.colors, ...action.color },
      };
    case SELECT_TEXT_COLOR:
      return { ...state, textColor: action.color };
    case SHOW_FILTER:
      return { ...state, currentFilter: action.filter };
    case HIDE_FILTER:
      return { ...state, currentFilter: "all" };
    case SPACING_FILTER:
      return {
        ...state,
        spacing: { ...action.spacing },
      };
    case COLOR_FILTER:
      return { ...state, colors: { ...state.colors, ...action.colors } };
    case STYLE_FILTER:
      return {
        ...state,
        styles: {
          selected: action.styles.textStyles,
          frequency: action.styles.frequency,
        },
      };
    case SIZE_FILTER:
      return {
        ...state,
        sizes: {
          selected: action.sizes.textSizes,
          frequency: action.sizes.frequency,
        },
      };
    case GET_PREFS:
      return {
        ...state,
        ...action.prefs,
      };
    case LAST_FILTER:
      return { ...state, lastFilter: action.filter };
    case SAVE_STORY_PAGE:
      return { ...state, ...action.prefs };
    case SHOW_FILTERS:
      return { ...state, showFilters: !state.showFilters };
    case SET_LINE_HEIGHT:
      return {
        ...state,
        spacing: { ...state.spacing, ...action.lineHeight },
      };
    case SET_LETTER_SPACING:
      return {
        ...state,
        spacing: { ...state.spacing, ...action.letterSpacing },
      };
    case CLEAR:
      return { ...initialState };
    default:
      return state;
  }
}
