import styled from "styled-components";

export const Animals = styled.div`
  width: 740px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 27px;
  margin-top: 18px;
  margin-bottom: 56px;
`;

export const UserWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 200px);
  grid-gap: 45px;
  width: 100%;
  height: 100%;
  margin: auto 0;
  padding: 0;
  justify-content: center;
`;

export const User = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 200px;
  border-radius: 15px;
  border: 1px solid #979797;
  background-color: #fff;
  color: #fff;
  cursor: pointer;
  img {
    margin: auto;
    display: block;
    width: 100px;
    height: 100px;
  }
  h1 {
    height: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border: ${(props) => props.theme.rovaOrange};
    font-size: 18px;
    text-align: center;
    background-color: ${(props) => props.theme.rovaOrange};
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 400;
    letter-spacing: 1px;
  }
  &:focus {
    border: 3px solid ${(props) => props.theme.rovaOrange};
  }
  & > * {
    pointer-events: none;
  }
`;

export const AddUser = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 200px;
  height: 200px;
  border-radius: 15px;
  border: 2px solid ${(props) => props.theme.rovaOrange};
  background-color: ${(props) => props.theme.lightGreen};
  color: #fff;
  cursor: pointer;
  img {
    align-self: center;
    width: 50px;
    height: 50px;
  }
  h1 {
    margin-top: 5px;
    height: 41px;
    color: ${(props) => props.theme.rovaOrange};
    font-size: 22px;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 400;
    letter-spacing: 1px;
  }
  &:focus {
    border: 3px solid ${(props) => props.theme.rovaOrange};
  }
  & > * {
    pointer-events: none;
  }
`;

export const LoginWrapper = styled.div`
  min-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding-top: 38px;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.rovaMain};
  & > h1 {
    margin-top: 38px;
    margin-bottom: 48px;
  }
  & > div {
    height: 100%;
  }
  overflow: scroll;
`;
