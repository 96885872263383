import React, { useEffect, useState } from "react";
import { Header } from "../../Components/Header";
import { Wrapper } from "../../Components/Wrapper";
import { H1 } from "../../Components/H1";
import { Input } from "../../Components/Input";
import { ErrorMessage } from "../../Components/ErrorMessage";
import { connect, useDispatch, useSelector } from "react-redux";
import { setUser } from "../../actions";
import {
  Animals,
  Animal,
  AnimalWrapper,
  SignupWrapper,
  GreenButton,
} from "./styles.js";
import { axiosClient } from "../../utils/axiosClient";
import { Progress } from "../../Components/Progress";

function SignupComponent(props) {
  const token = useSelector((state) => state.userReducer.token);
  const dispatch = useDispatch();
  const [svg, setSvg] = useState([]);
  const [name, setName] = useState("");
  const [avatar, setAvatar] = useState("");
  const [error, showError] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  function handleChange(e) {
    setName(e.target.value);
  }

  function importAll(r) {
    return r.keys().map(r).slice(0, 10);
  }

  function handleClick(e) {
    setAvatar(e.split("/")[3].split(".")[0]);
  }

  async function createUser() {
    // at least two character name
    if (avatar && name.length > 2) {
      // store.dispatch(setUser({ username: name, avatar }));
      const user = { username: name, avatar };
      setLoading(true);
      axiosClient
        .post("/users/createPreferences", user, {
          headers: {
            token,
          },
        })
        .then((res) => {
          setLoading(false);

          if (res.status === 200) {
            dispatch(setUser(res.data));

            props.history.push("/stories");
          }
        })
        .catch((e) => {
          setLoading(false);
          if (e.response && e.response.status === 409) {
            setNetworkError(true);
          }
        });
    } else {
      showError(true);
    }
  }

  useEffect(() => {
    setSvg(
      importAll(
        require.context("../../assets/animals", false, /\.(png|jpe?g|svg)$/)
      )
    );
  }, []);

  return (
    <Wrapper background="rovaMain">
      {isLoading && <Progress />}
      <Header />
      <SignupWrapper background="rovaMain">
        <H1
          color="rovaGray"
          style={{
            fontWeight: 400,
            fontSize: "30px",
            marginBottom: "27px",
            marginTop: "108px",
          }}
        >
          What's your name?
        </H1>
        <Input
          placeholder="Type your name"
          value={name}
          onChange={handleChange}
          maxLength="15"
        />
        <H1
          color="rovaGray"
          style={{
            fontSize: "30px",
            fontWeight: 400,
            marginTop: "35px",
            marginBottom: "18px",
          }}
        >
          Choose your avatar
        </H1>
        <Animals>
          {svg.map((q, index) => (
            <AnimalWrapper
              key={index}
              tabIndex={index + 1}
              onClick={() => handleClick(q)}
              style={{
                border:
                  avatar === q.split("/")[3].split(".")[0]
                    ? "3px solid #f0592b"
                    : null,
                outline: "none",
              }}
            >
              <Animal src={q} alt={q} />
            </AnimalWrapper>
          ))}
        </Animals>
        {error ? (
          <ErrorMessage>Please enter your name and select avatar</ErrorMessage>
        ) : null}
        <GreenButton type="button" onClick={createUser} background="rovaOrange">
          Next
        </GreenButton>
        {networkError ? (
          <ErrorMessage>Username already taken</ErrorMessage>
        ) : null}
      </SignupWrapper>
    </Wrapper>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    onTodoClick: (user) => {
      dispatch(setUser(user));
    },
  };
};

const mapStateToProps = (state) => {
  return { user: state.userReducer.user };
};

export const Signup = connect(
  mapStateToProps,
  mapDispatchToProps
)(SignupComponent);
