import React from "react";

import { Switch, Route } from "react-router-dom";

import { Welcome } from "./Containers/Welcome";
import { FirebaseLogin } from "./Containers/Welcome/FirebaseLogin";
import { Signup } from "./Containers/Signup";
import { Login } from "./Containers/Login";
import { Stories } from "./Containers/Stories";
import { Story } from "./Containers/Story";

function App() {
  return (
    <Switch>
      <Route path="/welcome" exact component={Welcome} />
      <Route path="/signup" component={Signup} />
      <Route path="/login" component={Login} />
      <Route path="/stories" component={Stories} />
      <Route path="/story" component={Story} />
      <Route path="/" exact component={FirebaseLogin} />
    </Switch>
  );
}

export default App;
