import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Header } from "../../Components/Header";
import { Wrapper } from "../../Components/Wrapper";
import { H1 } from "../../Components/H1";
import { Button } from "../../Components/Button";
import {
  ButtonsWrapper,
  StoriesWrapper,
  StoryRectangle,
  StoriesContainer,
} from "./styles";

import { selectCurrentStory } from "../../actions/userActions";
import { Progress } from "../../Components/Progress";

import { axiosClient } from "../../utils/axiosClient";

import _ from "lodash";

function StoriesComponent({ prefs, history, level }) {
  const dispatch = useDispatch();
  const [stories, setStories] = React.useState([]);
  const [initialStories, setInitialStories] = React.useState([]);
  const [isLoading, setLoading] = React.useState(false);
  const token = useSelector((state) => state.userReducer.token);
  function handleClick(story) {
    dispatch(selectCurrentStory(story));
    history.push("/story");
  }

  function filter(level) {
    let shallow = initialStories.slice();
    let filtered = shallow.filter((x) => x.level === level);
    setStories(filtered);
  }

  function filterAllStories() {
    setStories(initialStories);
  }

  function getStories() {
    setLoading(true);
    axiosClient.get("/stories", { headers: { token } }).then((res) => {
      setTimeout(() => {
        if (
          _.isEqual(_.sortBy(stories), _.sortBy(res.data)) === false ||
          _.isEqual(_.sortBy(initialStories), _.sortBy(res.data)) === false
        ) {
          setStories(res.data);
          setInitialStories(res.data);
          setLoading(false);
        }
      }, 3500);
    });
  }

  useEffect(() => {
    getStories();
  }, []);

  return (
    <>
      {isLoading && <Progress />}
      <Wrapper>
        <Header />
        <StoriesWrapper style={{ paddingTop: "100px" }}>
          <H1 color="rovaGray" style={{ fontWeight: 400 }}>
            Pick a story you want to read
          </H1>
          <ButtonsWrapper>
            <div>
              <Button
                color="#000"
                background="lightGreen"
                onClick={() => filter("1")}
                style={{ border: "1px solid #5fc881" }}
              >
                Level 1
              </Button>
              <Button
                color="#000"
                background="lightYellow"
                onClick={() => filter("2")}
                style={{ border: "1px solid #ffde17" }}
              >
                Level 2
              </Button>
              <Button
                color="#000"
                background="pink"
                onClick={() => filter("3")}
                style={{ border: "1px solid #d8191f" }}
              >
                Level 3
              </Button>
              <Button
                color="#000"
                background="level4"
                onClick={() => filter("4")}
                style={{ border: "1px solid #A67BC7" }}
              >
                Level 4
              </Button>
              <Button
                color="#000"
                background="zimaBlue"
                onClick={() => filter("5")}
                style={{ border: "1px solid #68B0CC" }}
              >
                Level 5
              </Button>
            </div>
            <p onClick={filterAllStories} style={{ color: "#333" }}>
              Show All
            </p>
          </ButtonsWrapper>
          <StoriesContainer>
            {stories.map((story) => (
              <StoryRectangle
                onClick={() => handleClick(story)}
                key={story.title}
                level={story.level}
              >
                <img alt="story" src={story.image} />
                <H1>{story.title}</H1>
              </StoryRectangle>
            ))}
          </StoriesContainer>
        </StoriesWrapper>
      </Wrapper>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    stories: state.userReducer.stories,
    initialStories: state.userReducer.initialStories,
    level: state.userReducer.level,
    story: state.userReducer.story,
  };
};

export const Stories = connect(mapStateToProps)(StoriesComponent);
