import styled from "styled-components";

export const Input = styled.input`
  width: 100%;
  height: 63px;
  border-radius: 10px;
  border: 0;
  padding: 20px 21px;
  box-sizing: border-box;
  font-size: 20px;

  ::placeholder {
    color: #837c7c;
  }
`;
