import _ from "lodash";
// Structured Data
const defaultAttributes = ["Georgia", "black", "16"]; // enum: [char:string, style:string, color:string, size:string]

export function stylingFunction(storyContent, options) {
  // Data Wrangling
  const sentences = storyContent.split("\n"); // split story string into array of sentence strings
  const metaSentences = sentences.map((s) =>
    s.split("").map((char) => [char, ...defaultAttributes])
  ); // map chars to structured data
  // const alphaNumeric = /[a-zA-Z0-9]/; // regex utility to identify alphaNumeric chars
  const alphaNumeric = /^[a-zA-Z0-9'`’!?#]*$/; // regex for alpha numeric chars and period, question and exclamation marks.
  const { colors, fonts, sizes } = options; // pluck options

  // note: order of elements in this array matches defaultAttributes.
  [fonts, colors, sizes].forEach(({ frequency, selected }, index) => {
    const attributeIndex = index + 1;

    if (frequency === "everySentence") {
      let selectedPointer = 0;
      for (let metaSentence of metaSentences) {
        metaSentence.forEach((metaChar) => {
          if (alphaNumeric.test(metaChar[0])) {
            metaChar[attributeIndex] = selected[selectedPointer];
          }
        });
        selectedPointer++;
        if (selectedPointer === selected.length) selectedPointer = 0;
      }
    }
    if (frequency === "everyFourWords") {
      let wordCount = 1;
      let selectedPointer = 0;
      for (let metaSentence of metaSentences) {
        metaSentence.forEach((metaChar) => {
          const [char] = metaChar;
          if (char === " ") wordCount++;
          else metaChar[attributeIndex] = selected[selectedPointer];
          if (wordCount >= 4) {
            wordCount = 1;
            selectedPointer++;
            if (selectedPointer === selected.length) selectedPointer = 0;
          }
        });
      }
    }

    if (frequency === "everyWord") {
      for (let metaSentence of metaSentences) {
        let selectedPointer = 0;
        metaSentence.forEach((metaChar) => {
          const [char] = metaChar;
          if (char === " ") selectedPointer++;
          // space means new word is next
          // if (!alphaNumeric.test(char))
          else metaChar[attributeIndex] = selected[selectedPointer]; // apply attribute if char is alphaNumeric
          if (selectedPointer === selected.length) selectedPointer = 0; // reset selection pointer at end of array
        });
        selectedPointer++;
      }
    }

    if (frequency === "everyLetter") {
      for (let metaSentence of metaSentences) {
        let selectedPointer = 0;
        metaSentence.forEach((metaChar) => {
          const [char] = metaChar;
          if (alphaNumeric.test(char)) {
            metaChar[attributeIndex] = selected[selectedPointer]; // apply attribute if char is alphaNumeric
            selectedPointer++;
          }
          if (selectedPointer === selected.length) selectedPointer = 0; // reset selection pointer at end of array
        });
      }
    }
  });

  return metaSentences;
}
export function randomNoRepeats(
  frequency,
  colors,
  paragraphIndex,
  sentenceIndex,
  wordIndex,
  charIndex
) {
  let key;
  const lastColorKey = `${colors.sort()}_LAST_COLOR`;
  switch (frequency) {
    case "everyLetter":
      key = `STORY_CHAR_COLOR_${colors.sort()}_${paragraphIndex}_${sentenceIndex}_${wordIndex}_${charIndex}`;
      break;
    case "everySentence":
      key = `STORY_CHAR_COLOR_${colors.sort()}_${sentenceIndex}`;
      break;
    case "everyFourWords":
      key = `STORY_CHAR_COLOR_${colors.sort()}_${paragraphIndex}_${Math.ceil(
        wordIndex / 4
      )}`;
      break;
    case "everyWord":
      key = `STORY_CHAR_COLOR_${colors.sort()}_${paragraphIndex}_${wordIndex}`;
      break;
  }
  let color = localStorage.getItem(key);
  const binKey = `${colors.sort()}_BIN_KEY`;
  if (color) {
    return color;
  }
  let bin = localStorage.getItem(binKey);
  if (bin) {
    bin = bin.split(",");
  } else {
    bin = colors;
  }
  if (bin.length === 0) {
    bin = _.shuffle(colors);
  } else {
    bin = _.shuffle(bin);
  }
  if (bin[0] === localStorage.getItem(lastColorKey)) {
    const b = bin[0];
    bin[0] = bin[1];
    bin[1] = b;
  }
  color = bin[0];
  localStorage.setItem(lastColorKey, color);
  localStorage.setItem(binKey, bin.slice(1));
  localStorage.setItem(key, color);
  return color;
}

// // Test Inputs
// const storyContent =
//   "What is yummy?\nWhat does everybody love?\nWhat has cheese on it?\nWhat has dough?\nChomp!\nIt is pizza!\nYummy!\nDifferent kinds of pizza.\nCheese pizza. A lot of people call cheese pizza normal pizza.\nA lot of people like cheese pizza.\nChicken pizza, not many people know about chicken pizza.\nBut some people like it.\nSausage pizza. Sausage pizza is good too.\nPepperoni pizza is really good. It has cheese on it and most people like it.\nThe different colors of pizza.\nUsually the color of pizza is red. It is red because under the cheese is the sauce and the sauce is red. Another color of pizza is yellow, this is the color of cheese.\nThe different shapes of pizza.\nThe different shapes of pizza are, circle and triangle. The circle is a whole pizza and the triangle is a slice of pizza.\nThe End";
// // note: frequency options are: 'perLetter', 'perWord', 'perFourWords', 'perSentence'
// const charOptions = {
//   colors: {
//     // frequency: 'perWord',
//     frequency: "everyWord",
//     selected: ["pink", "brown", "red"],
//   },
//   styles: {
//     frequency: "everyLetter",
//     selected: ["italic", "bold", "underline"],
//   },
//   sizes: {
//     frequency: "everySentence",
//     selected: [18, 20, 22],
//   },
// };

// const metaCharacters = stylingFunction(storyContent, charOptions);
