export const theme = {
  green: "#5fc881",
  blue: "#3374b5",
  white: "#ffffff",
  lightGreen: "#c5e0b3",
  darkGrey: "#2b2e30",
  lightYellow: "#fef0bb",
  pink: "#f4c9ba",
  skyBlue: "#40E0D0",
  yellow: "#fff0b4",
  grey: "#878787",
  purple: "#9b339d",
  brow: "#c65914",
  red: "#ff0200",
  orange: "#f4b184",
  darkYellow: "#ffda66",
  darkPink: "#f40f83",
  billieEilish: "#84ea1c",
  darkBlue: "#4571C4",
  greenBlue: "#3ccdb3",
  violet: "#b4c7e7",
  forestGreen: "#4b7a47",
  _red: "#ff0000",
  _purple: "#6a0dad",
  _green: "#4b7a47",
  _black: "#000000",
  _blue: "#00008b",
  _yellow: "#ffff00",
  _pink: "#FF69B4",
  _limeGreen: "#32cd32",
  _brown: "#654321",
  zimaBlue: "#83DCFF",
  level4: "#CE99F7",
  rovaMain: "#fff4b4",
  rovaGray: "#333333",
  rovaOrange: "#f0592b",
};
