import React, { useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Modal from "@material-ui/core/Modal";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import InputAdornment from "@material-ui/core/InputAdornment";

import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

export const RectangleWrapper = styled.div`
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;
  padding-bottom: 20%;
  div {
    cursor: pointer;
  }
`;

export const FullScreenModalComponent = styled(Modal)`
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  height: auto;
  min-height: 100vh;
  background-color: white;
  border-radius: 0;
  width: auto;
  padding: 0;
  div {
    padding: 0;
  }
`;

export const CenteredBox = styled(Box)`
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    button {
      width: 100%;
    }
  }
`;

export const StyledTextField = styled(TextField)`
  width: 250px;
`;

export function FullScreenModal({
  open,
  handleClose,
  onSubmit,
  email,
  password,
  handleEmail,
  handlePassword,
}) {
  const [showPlain, setShowPlain] = useState(false);
  return (
    <>
      <CssBaseline />
      <FullScreenModalComponent open={open} onClose={handleClose}>
        <Container maxWidth="xl">
          <Typography
            component="div"
            style={{
              backgroundColor: "#cfe8fc",
              height: "100vh",
              width: "100vw",
            }}
          >
            <CenteredBox>
              <form onSubmit={onSubmit}>
                <StyledTextField
                  id="email"
                  value={email}
                  onChange={handleEmail}
                  label="Email"
                />
                <StyledTextField
                  type={showPlain ? "text" : "password"}
                  id="password"
                  value={password}
                  onChange={handlePassword}
                  label="Password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="start"
                        onClick={() => setShowPlain(!showPlain)}
                        style={{ cursor: "pointer" }}
                      >
                        {showPlain ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </InputAdornment>
                    ),
                  }}
                />
                <Button color="primary" type="submit">
                  Sign in
                </Button>
              </form>
            </CenteredBox>
          </Typography>
        </Container>
      </FullScreenModalComponent>
    </>
  );
}
