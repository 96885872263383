import styled, { css } from "styled-components";

export const Rectangle = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 15px;
  ${(props) =>
    props.background &&
    css`
      background: ${(props) => props.theme[props.background]};
    `};
  color: ${(props) => props.theme.white};
  h1 {
    margin: auto;
    cursor: pointer;
    font-size: 25px;
  }

  display: flex;
  align-items: center;
  justify-content: center;
`;
