import React from "react";

import { Rectangle } from "../../Components/Rectangle";
import { Wrapper } from "../../Components/Wrapper";
import { WelcomeMessage } from "../../Components/WelcomeMessage";
import { RectangleWrapper } from "./styles";
import { H1 } from "../../Components/H1";
import { StyledLink } from "../../Components/StyledLink";

export function Welcome() {
  return (
    <Wrapper background="rovaMain">
      <WelcomeMessage />
      <H1 style={{ fontSize: "30px", fontWeight: "400" }} color="rovaGray">
        Have you used it before?
      </H1>
      <RectangleWrapper>
        <StyledLink to="login">
          <Rectangle background="green">
            <h1>YES</h1>
          </Rectangle>
        </StyledLink>
        <StyledLink to="signup">
          <Rectangle background="green">
            <h1>NO</h1>
          </Rectangle>
        </StyledLink>
      </RectangleWrapper>
    </Wrapper>
  );
}
