import React from "react";
import styled from "styled-components";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { Letters } from "../Letters";
import { H1 } from "../../Components/H1";
import { UserLabel } from "../UserLabel/";
import Stories from "../../assets/icons/script.svg";
import Prefs from "../../assets/icons/group-2.svg";
import _ from "lodash";
import Exit from "../../assets/icons/logout.svg";

import {
  toggleFiltersVisibility,
  clearReducer,
} from "../../actions/readerActions";

const HeaderWrapper = styled.div`
  width: 100%;
  min-height: 73px;
  position: fixed;
  margin: 0 auto;
  padding: 0 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  top: 0;
  box-sizing: border-box;
  & span {
    font-size: 30px;
    height: 30px;
    line-height: 30px;
    margin: auto 0;
  }
`;

const HeaderElement = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    width: 30px;
    height: 30px;
    margin-bottom: 8px;
  }
`;

const HeaderComponent = ({ user, history }) => {
  const dispatch = useDispatch();

  function logoutAction() {
    dispatch(clearReducer());
    history.push("/login");
  }
  function toggleFilters() {
    dispatch(toggleFiltersVisibility());
  }
  return (
    <HeaderWrapper
      id="header"
      style={{
        justifyContent:
          window.location.pathname === "/login" ||
          window.location.pathname === "/signup"
            ? "flex-end"
            : "space-between",
      }}
    >
      {!user ||
      window.location.pathname === "/signup" ||
      window.location.pathname === "/login" ? null : (
        <UserLabel user={user} />
      )}
      <div
        style={{
          margin: "auto",
          justifySelf: "center",
          position: "absolute",
          left: "calc(50% - 55px)",
        }}
      >
        <Letters />
      </div>
      <div style={{ display: "flex", justifySelf: "flex-end" }}>
        {window.location.pathname === "/login" ||
        window.location.pathname === "/signup" ||
        window.location.pathname === "/stories" ? null : (
          <HeaderElement
            style={{ marginRight: "112px" }}
            onClick={() => history.push("/stories")}
          >
            <img src={Stories} alt="exit" />
            <H1 color="rovaOrange">Stories</H1>
          </HeaderElement>
        )}
        {window.location.pathname === "/story" && (
          <HeaderElement
            style={{ marginRight: "112px" }}
            onClick={toggleFilters}
          >
            <img src={Prefs} alt="exit" />
            <H1 color="rovaOrange">My Choices</H1>
          </HeaderElement>
        )}
        {window.location.pathname !== "/login" && (
          <HeaderElement onClick={logoutAction}>
          <img src={Exit} alt="exit" />
          <H1 color="rovaOrange">Exit</H1>
        </HeaderElement>
        )}      
      </div>
    </HeaderWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    showFilters: state.readerReducer.showFilters,
  };
};

export const Header = compose(
  withRouter,
  connect(mapStateToProps, null)
)(HeaderComponent);
