import {
  CURRENT_USER,
  SAVE_USER,
  ALL_USERS,
  SET_USER,
  ALL_STORIES,
  FILTER_STORIES,
  SHOW_ALL,
  CURRENT_STORY,
  ERROR,
  SAVE_USER_TOKEN,
  LOGOUT,
} from "../actions";

const initialState = {
  users: [],
  user: {},
  stories: [],
  initialStories: [],
  level: "ALL",
  story: {},
  token: "",
};

export function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER:
    case SAVE_USER:
      return Object.assign({}, state, {
        user: action.user,
      });
    case ALL_USERS:
      return Object.assign({}, state, {
        users: action.users,
      });
    case CURRENT_USER:
      return Object.assign({}, state, {
        user: action.user,
      });
    case ALL_STORIES:
      return {
        ...state,
        stories: action.stories,
        initialStories: action.stories,
      };
    case FILTER_STORIES:
      return {
        ...state,
        stories: state.initialStories.filter(
          (story) => story.level === action.level
        ),
      };
    case ERROR:
      return { ...state, error: 409 };
    case SHOW_ALL:
      return { ...state, stories: action.stories, level: "ALL" };
    case CURRENT_STORY:
      return { ...state, story: action.story, text: action.story };
    case SAVE_USER_TOKEN:
      return { ...state, token: action.token };
    case LOGOUT:
      return {};
    default:
      return state;
  }
}
