const SET_USER = "SET_USER";
const GET_USER = "GET_USER";
const SAVE_USER = "SAVE_USER";
const ALL_USERS = "ALL_USERS";
const CURRENT_USER = "CURRENT_USER";
const ALL_STORIES = "ALL_STORIES";
const FILTER_STORIES = "FILTER_STORIES";
const SHOW_ALL = "SHOW_ALL";
const CURRENT_STORY = "CURRENT_STORY";
const LOGOUT = "LOGOUT";
const ERROR = "ERROR";

// reader
const SELECT_TEXT_COLOR = "SELECT_TEXT_COLOR";
const SELECT_BG_COLOR = "SELECT_BG_COLOR";
const COLOR_FILTER = "COLOR_FILTER";
const STYLE_FILTER = "STYLE_FILTER";
const SIZE_FILTER = "SIZE_FILTER";
const SPACING_FILTER = "SPACING_FILTER";
const SHOW_FILTER = "SHOW_FILTER";
const HIDE_FILTER = "HIDE_FILTER";

const GET_PREFS = "GET_PREFS";
const SELECT_BG_COLOR_ALPHA = "SELECT_BG_COLOR_ALPHA";
const SPACING_OPTION = "SPACING_OPTION";
export {
  SELECT_BG_COLOR_ALPHA,
  GET_USER,
  SET_USER,
  SAVE_USER,
  ALL_USERS,
  CURRENT_USER,
  ALL_STORIES,
  FILTER_STORIES,
  SHOW_ALL,
  CURRENT_STORY,
  SELECT_BG_COLOR,
  SELECT_TEXT_COLOR,
  COLOR_FILTER,
  STYLE_FILTER,
  SIZE_FILTER,
  SHOW_FILTER,
  HIDE_FILTER,
  LOGOUT,
  GET_PREFS,
  ERROR,
  SPACING_OPTION,
  SPACING_FILTER,
};

export const SAVE_USER_TOKEN = "SAVE_USER_TOKEN";
export const LAST_FILTER = "LAST_FILTER";
export const SAVE_STORY_PAGE = "SAVE_STORY_PAGE";
export const SHOW_FILTERS = "SHOW_FILTERS";
export const CLEAR = "CLEAR";
export const SET_LINE_HEIGHT = "SET_LINE_HEIGHT";
export const SET_LETTER_SPACING = "SET_LETTER_SPACING";
