import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { IoIosArrowBack } from "react-icons/io";
import {
  ExpandedFilter,
  SpacingOptionsWrapper,
  SpacingOption,
  ButtonsWrapper,
} from "./styles.js";
import { changeSpacingOption, hideFilter, changeSpacing } from "../../actions";
import { H1 } from "../../Components/H1";
import { Button } from "../../Components/Button";

import { axiosClient } from "../../utils/axiosClient.js";
import { Progress } from "../../Components/Progress";
function SpacingFilterComponent({
  lineHeight,
  letterSpacing,
  user,
  preferences,
}) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.userReducer.token);
  const [currentOption, setCurrent] = useState("word");
  const [isLoading, setLoading] = useState(false);
  const { spacing } = preferences;
  useEffect(() => {
    if (spacing.hasOwnProperty("spacingOption")) {
      setCurrent(spacing.spacingOption);
    }
  }, []);

  function handleClick(option) {
    setCurrent(option);
    dispatch(changeSpacingOption(option));
  }

  async function handleClose() {
    const { lastFilter, currentFilter, ...prefs } = preferences;
    prefs.username = user.username;
    prefs.avatar = user.avatar;
    prefs.spacing.lineHeight = lineHeight;
    prefs.spacing.letterSpacing = letterSpacing;
    setLoading(true);
    await axiosClient.put("users/setPreferences", prefs, {
      params: { user_id: user.id },
      headers: { token },
    });
    setLoading(false);
    dispatch(
      changeSpacing({ lineHeight, letterSpacing, spacingOption: currentOption })
    );

    dispatch(hideFilter());
  }

  function closeFilter() {
    dispatch(hideFilter());
  }

  return (
    <>
      {isLoading && <Progress />}
      <ExpandedFilter>
        <SpacingOptionsWrapper>
          <H1
            color="rovaGray"
            style={{ fontSize: "20px", margin: "15px 0", width: "360px" }}
          >
            Spacing
          </H1>
          <H1
            style={{
              margin: "auto",
              marginTop: "23px",
              marginBottom: "18px",
              fontSize: "16px",
            }}
            color="rovaGray"
          >
            Pick to change space between words or sentences
          </H1>
          <SpacingOption
            color={currentOption === "word" ? "white" : "green"}
            background={currentOption === "word" ? "green" : "white"}
            onClick={() => handleClick("word")}
          >
            Space between words
          </SpacingOption>
          <SpacingOption
            color={currentOption === "sentence" ? "white" : "green"}
            background={currentOption === "sentence" ? "green" : "white"}
            onClick={() => handleClick("sentence")}
          >
            Space between sentences
          </SpacingOption>
          <ButtonsWrapper>
            <Button
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
              background="rovaOrange"
              color="white"
              onClick={closeFilter}
            >
              <IoIosArrowBack
                style={{
                  width: "2em",
                  height: "1.5em",
                  position: "absolute",
                  left: "10%",
                }}
              />
              Back
            </Button>
            <Button
              background="rovaOrange"
              color="white"
              onClick={handleClose}
              style={{
                fontSize: "16px",
                height: "41px",
                borderRadius: "3px",
                border: "none",
                width: "119px",
              }}
            >
              Save
            </Button>
          </ButtonsWrapper>
        </SpacingOptionsWrapper>
      </ExpandedFilter>
    </>
  );
}

const mapStateToProps = (state) => ({
  spacingOption: state.readerReducer.spacing.spacingOption,
  user: state.userReducer.user,
  preferences: state.readerReducer,
});

export const SpacingFilter = connect(
  mapStateToProps,
  null
)(SpacingFilterComponent);
