import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { H1 } from "../../Components/H1";
import { Button } from "../../Components/Button";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import {
  ExpandedFilter,
  BgColor,
  TextColor,
  TextColorWrapper,
  BgColorWrapper,
  FrequencyWrapper,
  BgColorAlpha,
  ButtonsWrapper,
} from "./styles.js";
import { theme } from "../../styles/theme.js";

import { MdFiberManualRecord } from "react-icons/md";
import { IconContext } from "react-icons";

import { hideFilter, changeColor, setLastFilter } from "../../actions";
import { axiosClient } from "../../utils/axiosClient.js";
import { Progress } from "../../Components/Progress";
import Draggable from "react-draggable";

const COLORS_ARRAY = ["skyBlue", "lightGreen", "pink", "yellow", "grey"];

function ColorFilterComponent({ user, preferences }) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.userReducer.token);
  const [selectedTextColors, setSelectedTextColors] = useState([]);
  const [backgroundColor, setBackgroundColor] = useState("");
  const [frequency, setFrequency] = useState("");
  const [firstStep, setFirstStep] = useState(true);
  const [XAxis, setXAxis] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const newColors = [
    "_red",
    "_green",
    "_purple",
    "_black",
    "_blue",
    "_yellow",
    "_pink",
    "_limeGreen",
    "_brown",
  ];

  const { colors } = preferences;
  useEffect(() => {
    if (colors.hasOwnProperty("selected")) {
      if (colors.selected.length !== 0) {
        setSelectedTextColors(colors.selected);
      }
    }
    if (colors.hasOwnProperty("backgroundColor")) {
      if (colors.backgroundColor) {
        setBackgroundColor(colors.backgroundColor);
      }
    }
    if (colors.hasOwnProperty("frequency")) {
      setFrequency(colors.frequency);
    }
  }, [preferences, colors, XAxis]);

  async function applyChanges() {
    const { lastFilter, currentFilter, ...prefs } = preferences;
    prefs.username = user.username;
    prefs.avatar = user.avatar;
    prefs.colors.selected = selectedTextColors;
    prefs.colors.backgroundColor = backgroundColor;
    prefs.colors.frequency = frequency;
    setLoading(true);
    await axiosClient.put("users/setPreferences", prefs, {
      params: { user_id: user.id },
      headers: { token },
    });
    setLoading(false);
    dispatch(
      changeColor({ selected: selectedTextColors, backgroundColor, frequency })
    );
    closeFilter();
  }

  function handleRadioChange(freq) {
    setFrequency(freq);
    dispatch(
      changeColor({
        selected: selectedTextColors,
        backgroundColor,
        frequency: freq,
      })
    );
  }

  function handleBgColor(bgColor) {
    if (bgColor !== colors.backgroundColor) {
      setXAxis(1);
    }
    const bgAlpha = hexToRGB(theme[bgColor]);
    const newColor =
      "rgb(" +
      Math.floor(bgAlpha[0]).toString() +
      "," +
      Math.floor(bgAlpha[1]).toString() +
      "," +
      Math.floor(bgAlpha[2]).toString() +
      ")";

    dispatch(
      changeColor({
        backgroundColor: bgColor,
        backgroundAlpha: newColor,
      })
    );
  }

  function closeFilter() {
    dispatch(hideFilter());
  }

  async function handleNextButton() {
    if (selectedTextColors.length === 1) {
      const { lastFilter, currentFilter, ...prefs } = preferences;
      prefs.username = user.username;
      prefs.avatar = user.avatar;
      setLoading(true);
      await axiosClient.put("/users/setPreferences", prefs, {
        params: { user_id: user.id },
        headers: { token },
      });
      setLoading(false);
      dispatch(
        changeColor({
          selected: selectedTextColors,
          backgroundColor,
          frequency: "entirePage",
        })
      );
      closeFilter();
    } else {
      setFirstStep(false);
    }
  }

  function hexToRGB(hex) {
    if (hex.charAt(0) === "#") {
      hex = hex.substr(1);
    }
    if (hex.length < 2 || hex.length > 6) {
      return false;
    }
    var values = hex.split(""),
      r,
      g,
      b;

    if (hex.length === 2) {
      r = parseInt(values[0].toString() + values[1].toString(), 16);
      g = r;
      b = r;
    } else if (hex.length === 3) {
      r = parseInt(values[0].toString() + values[0].toString(), 16);
      g = parseInt(values[1].toString() + values[1].toString(), 16);
      b = parseInt(values[2].toString() + values[2].toString(), 16);
    } else if (hex.length === 6) {
      r = parseInt(values[0].toString() + values[1].toString(), 16);
      g = parseInt(values[2].toString() + values[3].toString(), 16);
      b = parseInt(values[4].toString() + values[5].toString(), 16);
    } else {
      return false;
    }
    return [r, g, b];
  }

  function handleAlpha(e, x) {
    setXAxis(x.x);
    const rgb = hexToRGB(theme[backgroundColor]);
    const percent = XAxis / 336;
    const red = rgb[0] + percent * (255 - rgb[0]);
    const green = rgb[1] + percent * (255 - rgb[1]);
    const blue = rgb[2] + percent * (255 - rgb[2]);
    const newColor =
      "rgb(" +
      Math.floor(red).toString() +
      "," +
      Math.floor(green).toString() +
      "," +
      Math.floor(blue).toString() +
      ")";
    dispatch(
      changeColor({
        selected: selectedTextColors,
        backgroundAlpha: newColor,
        backgroundColor,
        frequency,
      })
    );
    dispatch(setLastFilter({ filter: "BGALPHA" }));
  }

  function handleTextColor(textColor) {
    if (selectedTextColors.length < 5) {
      if (selectedTextColors.indexOf(textColor) > -1) {
        setSelectedTextColors([
          ...selectedTextColors.filter((color) => color !== textColor),
        ]);
      } else {
        setSelectedTextColors([...selectedTextColors, textColor]);
      }
    } else {
      setSelectedTextColors([
        ...selectedTextColors.filter((color) => color !== textColor),
      ]);
    }
  }
  return (
    <>
      {isLoading && <Progress />}
      <ExpandedFilter>
        {firstStep ? (
          <>
            <H1 color="rovaGray" style={{ fontSize: "20px", margin: "15px 0" }}>
              Colors
            </H1>
            <H1
              style={{
                margin: "auto",
                marginTop: "23px",
                marginBottom: "18px",
              }}
              color="rovaGray"
            >
              Pick background color you like
            </H1>

            <BgColorWrapper>
              {COLORS_ARRAY.map((col) => (
                <BgColor
                  key={col}
                  onClick={() => handleBgColor(col)}
                  background={col}
                  checked={backgroundColor === col ? true : false}
                ></BgColor>
              ))}
              {/* <BgColor
                onClick={() => handleBgColor("skyBlue")}
                background="skyBlue"
                checked={backgroundColor === "skyBlue" ? true : false}
              ></BgColor>
              <BgColor
                onClick={() => handleBgColor("lightGreen")}
                background="lightGreen"
                checked={backgroundColor === "lightGreen" ? true : false}
              ></BgColor>
              <BgColor
                checked={backgroundColor === "pink" ? true : false}
                onClick={() => handleBgColor("pink")}
                background="pink"
              ></BgColor>
              <BgColor
                checked={backgroundColor === "yellow" ? true : false}
                onClick={() => handleBgColor("yellow")}
                background="yellow"
                style={{}}
              ></BgColor>
              <BgColor
                checked={backgroundColor === "grey" ? true : false}
                onClick={() => handleBgColor("grey")}
                background="grey"
              ></BgColor> */}
            </BgColorWrapper>
            <BgColorAlpha backgroundColor={backgroundColor || "black"}>
              <Draggable
                axis="x"
                allowAnyClick={true}
                handle=".handle"
                defaultPosition={{ x: XAxis, y: 0 }}
                position={{ x: XAxis, y: 0 }}
                grid={[1, 0.5]}
                scale={1}
                onDrag={handleAlpha}
                bounds={{ left: 0, right: 336 }}
              >
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="handle"
                >
                  <IconContext.Provider
                    value={{ color: "white", size: "26px" }}
                  >
                    <MdFiberManualRecord />
                  </IconContext.Provider>
                </div>
              </Draggable>
            </BgColorAlpha>
            <H1 color="blue">Pick up 5 colors you like</H1>
            <TextColorWrapper>
              {newColors.map((color, idx) => (
                <TextColor
                  key={idx}
                  onClick={() => handleTextColor(color)}
                  background={color}
                  checked={selectedTextColors.includes(color) ? true : false}
                ></TextColor>
              ))}
            </TextColorWrapper>

            <ButtonsWrapper>
              <Button
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                background="rovaOrange"
                color="white"
                onClick={closeFilter}
              >
                <IoIosArrowBack
                  style={{
                    width: "2em",
                    height: "1.5em",
                    position: "absolute",
                    left: "10%",
                  }}
                />
                Back
              </Button>
              <Button
                background="rovaOrange"
                color="white"
                style={{
                  lineHeight: "18px",
                  height: "41px",
                  minHeight: "41px",
                  borderRadius: "3px",
                  fontWeight: "800",
                }}
                onClick={handleNextButton}
              >
                Next
                <IoIosArrowForward
                  style={{
                    width: "2em",
                    height: "1.5em",
                    position: "absolute",
                    right: "10%",
                  }}
                />
              </Button>
            </ButtonsWrapper>
          </>
        ) : (
          <>
            <H1 color="rovaGray" style={{ fontSize: "20px", margin: "15px 0" }}>
              Colors
            </H1>
            <H1 color="rovaGray">Pick how often the color changes</H1>
            <FrequencyWrapper>
              <label>
                <input
                  type="radio"
                  name="frequency"
                  onChange={() => handleRadioChange("everyLetter")}
                  checked={frequency === "everyLetter"}
                />
                <span style={{ fontFamily: "Arial", fontWeight: "bold" }}>
                  <span style={{ color: "#9b339d" }}>C</span>
                  <span style={{ color: "#ff0200" }}>h</span>
                  <span style={{ color: "#f4b184" }}>a</span>
                  <span style={{ color: "#4b7a47" }}>n</span>
                  <span style={{ color: "#4571c4" }}>g</span>
                  <span style={{ color: "#f11502" }}>e</span>
                  <span> </span>
                  <span style={{ color: "#9b339d" }}>t</span>
                  <span style={{ color: "#ff0200" }}>h</span>
                  <span style={{ color: "#f4b184" }}>e</span>
                  <span> </span>
                  <span style={{ color: "#4b7a47" }}>c</span>
                  <span style={{ color: "#f4b184" }}>o</span>
                  <span style={{ color: "#9b339d" }}>l</span>
                  <span style={{ color: "#ff0200" }}>o</span>
                  <span style={{ color: "#f4b184" }}>r</span>
                  <span> </span>
                  <span style={{ color: "#4b7a47" }}>e</span>
                  <span style={{ color: "#4571c4" }}>v</span>
                  <span style={{ color: "#9b339d" }}>e</span>
                  <span style={{ color: "#ff0200" }}>r</span>
                  <span style={{ color: "#f4b184" }}>y</span>
                  <span> </span>
                  <span style={{ color: "#f4b184" }}>l</span>
                  <span style={{ color: "#4571c4" }}>e</span>
                  <span style={{ color: "#9b339d" }}>t</span>
                  <span style={{ color: "#ff0200" }}>t</span>
                  <span style={{ color: "#ff4b184" }}>e</span>
                  <span style={{ color: "#4b7a47" }}>r</span>
                </span>
              </label>
              <label>
                <input
                  type="radio"
                  name="frequency"
                  onChange={() => handleRadioChange("everyWord")}
                  checked={frequency === "everyWord"}
                />
                <span style={{ fontFamily: "Arial", fontWeight: "bold" }}>
                  <span style={{ color: "#9b339d" }}>Change</span>
                  <span> </span>
                  <span style={{ color: "#ff0200" }}>the</span>
                  <span> </span>
                  <span style={{ color: "#f4b184" }}>color</span>
                  <span> </span>
                  <span style={{ color: "#4b7a47" }}>every</span>
                  <span> </span>
                  <span style={{ color: "#4571c4" }}>word</span>
                </span>
              </label>

              <label>
                <input
                  type="radio"
                  name="frequency"
                  onChange={() => handleRadioChange("everyFourWords")}
                  checked={frequency === "everyFourWords"}
                />
                <span
                  style={{
                    fontFamily: "Arial",
                    fontWeight: "bold",
                  }}
                >
                  <span style={{ color: "#9b339d" }}>
                    Change the color every
                  </span>
                  <span> </span>
                  <span style={{ color: "#ff0200" }}>four words to have</span>
                  <span> </span>
                  <span style={{ color: "#f4b184" }}>it like this</span>
                </span>
              </label>

              <label>
                <input
                  type="radio"
                  name="frequency"
                  onChange={() => handleRadioChange("everySentence")}
                  checked={frequency === "everySentence"}
                />
                <span style={{ fontFamily: "Arial", fontWeight: "bold" }}>
                  <span style={{ color: "#9b339d" }}>
                    Change the color every sentence to look like this.
                  </span>
                  <span>
                    <br />
                  </span>
                  <span style={{ color: "#ff0200" }}>How does this look?</span>
                </span>
              </label>

              <label>
                <input
                  type="radio"
                  name="frequency"
                  onChange={() => handleRadioChange("entirePage")}
                  checked={frequency === "entirePage"}
                />
                <span
                  style={{
                    color: "#9b339d",
                    fontFamily: "Arial",
                    fontWeight: "bold",
                  }}
                >
                  Leave the color the same entire page
                </span>
              </label>
              <div>
                <Button
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  color="white"
                  onClick={() => setFirstStep(true)}
                >
                  <IoIosArrowBack
                    style={{ width: "2em", height: "1.5em", left: "10%" }}
                  />
                  Back
                </Button>
                <Button color="white" onClick={applyChanges}>
                  Save
                </Button>
              </div>
            </FrequencyWrapper>
          </>
        )}
      </ExpandedFilter>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  preferences: state.readerReducer,
});

export const ColorFilter = connect(mapStateToProps, null)(ColorFilterComponent);
