import React from "react";
import { CurrentUser } from "./styles.js";

export function UserLabel({ user }) {
  return (
    <CurrentUser>
      <img src={`/assets/animals/${user.avatar}.svg`} alt={user.avatar} />
      <h1>Hi {user.username}</h1>
    </CurrentUser>
  );
}
