import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import styled from "styled-components";
const ProgressScreen = styled.div`
  width: 100vw;
  height: 100vh;
`;
export const ProgressWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  background: rgba(246, 246, 246, 0.5);
  height: 100vh;
  width: 100vw;
`;
export const Progress = () => (
  <ProgressWrapper>
    <ProgressScreen>
      <LinearProgress style={{ color: "#5fc881" }} />
    </ProgressScreen>
  </ProgressWrapper>
);