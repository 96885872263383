import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { fetchUsers, currentUser } from "../../actions/userActions.js";
import { getUserPrefs } from "../../actions";
import { Header } from "../../Components/Header";
import { Wrapper } from "../../Components/Wrapper";
import { H1 } from "../../Components/H1";
import { User, AddUser, UserWrapper, LoginWrapper } from "./styles.js";
import { Progress } from "../../Components/Progress";
import AddUserIcon from "../../assets/icons/add_user.svg";

import { axiosClient } from "../../utils/axiosClient";

function LoginComponent({ history, prefs }) {
  const [users, setUsers] = React.useState([]);
  const [isLoading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.userReducer.token);

  async function handleClick(e, user) {
    dispatch(currentUser(user));
    dispatch(getUserPrefs(user));
    history.push("/stories");
  }

  function getUsers() {
    setLoading(true);
    axiosClient.get("/users", { headers: { token } }).then((res) => {
      setUsers(res.data);
      setLoading(false);
    });
  }

  useEffect(() => {
    getUsers();
  }, [dispatch, prefs]);

  return (
    <Wrapper>
      {isLoading && <Progress />}
      <Header />
      <LoginWrapper style={{ width: "100%" }}>
        <H1
          style={{ marginTop: "75px", fontWeight: "400", fontSize: "30px" }}
          color="rovaGray"
        >
          Select Your Name and Profile
        </H1>
        {
          <UserWrapper>
            <AddUser onClick={(e) => history.push("/signup")}>
                <img
                  alt='Add User'
                  src={AddUserIcon}
                />
                <H1>Add User</H1>
            </AddUser>
            {users.map((user) => (
              <User
                key={user.id}
                name={user.username}
                onClick={(e) => handleClick(e, user)}
              >
                <img
                  alt={user.avatar}
                  src={`/assets/animals/${user.avatar}.svg`}
                />
                <H1>{user.username}</H1>
              </User>
            ))}
          </UserWrapper>
        }
      </LoginWrapper>
    </Wrapper>
  );
}

const mapStateToProps = (state) => {
  return {
    users: state.userReducer.users,
    user: state.userReducer.user,
  };
};

const mapDispatchToProps = {
  fetchUsers,
  currentUser,
};

export const Login = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginComponent);
