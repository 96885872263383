import thunkMiddleware from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import { createLogger } from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";
import { rootReducer } from "./reducers";

import { persistStore, persistReducer } from "redux-persist";
import localForage from "localforage";

const loggerMiddleware = createLogger();

localForage.config({
  name: "Visual Reader",
  storeName: "reader",
});

const persistConfig = {
  key: "root",
  storage: localForage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunkMiddleware, loggerMiddleware))
);
export const persistor = persistStore(store);
