import axios from "axios";
import { store } from "../store";
// import { history } from "../index";
// import { logout } from "../actions/userActions.js";
import * as firebase from "firebase/app";
import "firebase/auth";
import { saveUserToken } from "../actions/userActions.js";

const project = process.env.REACT_APP_PROJECT;

export const axiosClient = axios.create({
  baseURL: "https://us-central1-" + project +  ".cloudfunctions.net/app/",
  headers: {
    "Content-Type": "application/json; charset=utf-8",
    Accept: "application/json",
    withCredentials: true,
  },
});

axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.config && error.response.status === 401) {
      return new Promise((resolve, reject) => {
        console.log(error.config);
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            user.getIdToken().then((token) => {
              store.dispatch(saveUserToken(token));
              axiosClient.defaults.headers.common["token"] = token;
              error.config.headers.token = token;
              error.config.__isRetryRequest = true;
              return axios.request(error.config).then(resolve, reject);
            });
          }
        });
      });
    }
  }
);
