import styled from "styled-components";
import { Button } from "../../Components/Button";

export const Animals = styled.div`
  width: 740px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 27px;
  margin-top: 18px;
  margin-bottom: 56px;
`;

export const AnimalWrapper = styled.div`
  display: flex;
  text-align: center;
  height: 110px;
  width: 110px;
  border-radius: 15px;
  background-color: white;
  box-sizing: border-box;
  img {
    margin: auto;
    display: block;
  }
`;

export const Animal = styled.img`
  width: 90px;
  height: 90px;
`;

export const SignupWrapper = styled.div`
  width: 750px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: auto;
  background-color: ${(props) => props.theme.rovaMain};
`;

export const GreenButton = styled(Button)`
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 1px;
  color: #fff;
  cursor: pointer;
  height: 63px;
`;
