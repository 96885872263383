import React from "react";
import styled from "styled-components";
// import { Letters } from "../Letters";
import Sign from "../../assets/sign.png";
const H1 = styled.h1`
  color: ${(props) => props.theme.rovaGray};
  font-size: 30px;
  text-align: center;
  font-weight: 400;
`;

export const WelcomeMessage = () => {
  return (
    <H1>
      Welcome to
      <br />
      <img
        src={Sign}
        style={{ width: "50%", marginTop: "28px" }}
        alt="rova-reader-sign"
      />
      {/* <Letters /> */}
    </H1>
  );
};
