import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { H1 } from "../../Components/H1";
import { Button } from "../../Components/Button";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import {
  ExpandedFilter,
  TextSizesWrapper,
  FrequencyWrapper,
  ButtonsWrapper,
} from "./styles.js";

import { hideFilter, changeSize } from "../../actions";
import { axiosClient } from "../../utils/axiosClient.js";
import { Progress } from "../../Components/Progress";

function SizeFilterComponent({ user, preferences }) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.userReducer.token);
  const [frequency, setFrequency] = useState("");
  const [firstStep, setFirstStep] = useState(true);
  const [textSizes, setTextSizes] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const { sizes } = preferences;
  useEffect(() => {
    if (sizes.hasOwnProperty("selected")) {
      setTextSizes(sizes.selected);
    }

    if (sizes.hasOwnProperty("frequency")) {
      setFrequency(sizes.frequency);
    }
  }, []);

  async function applyChanges() {
    const { lastFilter, currentFilter, ...prefs } = preferences;
    prefs.username = user.username;
    prefs.avatar = user.avatar;
    prefs.sizes.selected = textSizes;
    prefs.sizes.frequency = frequency;
    setLoading(true);
    await axiosClient.put("users/setPreferences", prefs, {
      params: { user_id: user.id },
      headers: { token },
    });
    setLoading(false);
    dispatch(changeSize({ textSizes, frequency }));
    closeFilter();
  }

  function handleRadioChange(freq) {
    setFrequency(freq);
    dispatch(changeSize({ textSizes, frequency: freq }));
  }

  function closeFilter() {
    dispatch(hideFilter());
  }
  const sizesArray = ["24", "28", "30", "32", "34"];

  function handleSize(e) {
    if (textSizes.length < 3) {
      if (textSizes.some((size) => size === e.target.value)) {
        setTextSizes([
          ...textSizes.filter((textSize) => textSize !== e.target.value),
        ]);
      } else {
        setTextSizes([...textSizes, e.target.value]);
      }
    } else {
      setTextSizes([
        ...textSizes.filter((textSize) => textSize !== e.target.value),
      ]);
    }
  }

  async function handleNextButton() {
    if (textSizes.length === 1) {
      const { lastFilter, currentFilter, ...prefs } = preferences;
      prefs.username = user.username;
      prefs.avatar = user.avatar;
      setLoading(true);
      await axiosClient.put("users/setPreferences", prefs, {
        params: { user_id: user.id },
        headers: { token },
      });
      setLoading(false);
      dispatch(changeSize({ textSizes, frequency: "entirePage" }));
      closeFilter();
    } else {
      setFirstStep(false);
    }
  }

  return (
    <>
      {isLoading && <Progress />}
      <ExpandedFilter>
        {firstStep ? (
          <>
            <H1 color="rovaGray" style={{ fontSize: "20px", margin: "15px 0" }}>
              Word Size
            </H1>
            <H1 color="rovaGray">Pick up to 3 word sizes you like</H1>
            <TextSizesWrapper>
              {sizesArray.map((size) => (
                <div key={size}>
                  <input
                    style={{ width: "27px", height: "27px" }}
                    type="checkbox"
                    checked={textSizes.includes(size)}
                    value={size}
                    onChange={handleSize}
                  />
                  <label
                    style={{ fontSize: `${size}px`, verticalAlign: "middle" }}
                  >
                    This is size {size}
                  </label>
                </div>
              ))}
            </TextSizesWrapper>
            <ButtonsWrapper>
              <Button
                background="rovaOrange"
                color="white"
                onClick={closeFilter}
              >
                <IoIosArrowBack
                  style={{
                    width: "2em",
                    height: "1.5em",
                    position: "absolute",
                    left: "10%",
                  }}
                />
                Back
              </Button>
              <Button
                background="rovaOrange"
                color="white"
                style={{ fontSize: "14px", height: "41px" }}
                onClick={handleNextButton}
              >
                Next
                <IoIosArrowForward
                  style={{
                    width: "2em",
                    height: "1.5em",
                    position: "absolute",
                    right: "10%",
                  }}
                />
              </Button>
            </ButtonsWrapper>
          </>
        ) : (
          <>
            <H1 color="rovaGray" style={{ fontSize: "20px", margin: "15px 0" }}>
              Word Size
            </H1>
            <H1 color="rovaGray">Pick how often the size changes</H1>
            <FrequencyWrapper>
              <label>
                <input
                  type="radio"
                  name="frequency"
                  onChange={() => handleRadioChange("everyLetter")}
                  checked={frequency === "everyLetter"}
                />
                <span>
                  <span style={{ fontSize: "18px" }}>C</span>
                  <span style={{ fontSize: "20px" }}>h</span>
                  <span style={{ fontSize: "24px" }}>a</span>
                  <span style={{ fontSize: "18px" }}>n</span>
                  <span style={{ fontSize: "20px" }}>g</span>
                  <span style={{ fontSize: "24px" }}>e</span>
                  <span> </span>
                  <span style={{ fontSize: "18px" }}>t</span>
                  <span style={{ fontSize: "20px" }}>h</span>
                  <span style={{ fontSize: "24px" }}>e</span>
                  <span> </span>
                  <span style={{ fontSize: "18px" }}>s</span>
                  <span style={{ fontSize: "20px" }}>i</span>
                  <span style={{ fontSize: "24px" }}>z</span>
                  <span style={{ fontSize: "18px" }}>e</span>
                  <span> </span>
                  <span style={{ fontSize: "20px" }}>e</span>
                  <span style={{ fontSize: "24px" }}>v</span>
                  <span style={{ fontSize: "18px" }}>e</span>
                  <span style={{ fontSize: "20px" }}>r</span>
                  <span style={{ fontSize: "24px" }}>y</span>
                  <span> </span>
                  <span style={{ fontSize: "18px" }}>l</span>
                  <span style={{ fontSize: "20px" }}>e</span>
                  <span style={{ fontSize: "24px" }}>t</span>
                  <span style={{ fontSize: "18px" }}>t</span>
                  <span style={{ fontSize: "20px" }}>e</span>
                  <span style={{ fontSize: "24px" }}>r</span>
                </span>
              </label>
              <label>
                <input
                  type="radio"
                  name="frequency"
                  onChange={() => handleRadioChange("everyWord")}
                  checked={frequency === "everyWord"}
                />
                <span>
                  <span style={{ fontSize: "18px" }}>Change</span>
                  <span> </span>
                  <span style={{ fontSize: "20px" }}>the</span>
                  <span> </span>
                  <span style={{ fontSize: "24px" }}>font</span>
                  <span> </span>
                  <span style={{ fontSize: "18px" }}>size</span>
                  <span> </span>
                  <span style={{ fontSize: "20px" }}>every</span>
                  <span> </span>
                  <span style={{ fontSize: "24px" }}>word</span>
                </span>
              </label>

              <label>
                <input
                  type="radio"
                  name="frequency"
                  onChange={() => handleRadioChange("everyFourWords")}
                  checked={frequency === "everyFourWords"}
                />
                <span>
                  <span style={{ fontSize: "18px" }}>
                    Change the size every
                  </span>
                  <span> </span>
                  <span style={{ fontSize: "20px" }}>four words if you</span>
                  <span> </span>
                  <span style={{ fontSize: "24px" }}>like the size change</span>
                </span>
              </label>

              <label>
                <input
                  type="radio"
                  name="frequency"
                  onChange={() => handleRadioChange("everySentence")}
                  checked={frequency === "everySentence"}
                />
                <span>
                  <span style={{ fontSize: "18px" }}>
                    Change the size every sentence.
                  </span>
                  <span> </span>
                  <span style={{ fontSize: "20px" }}>
                    Pick this if you like.
                  </span>
                </span>
              </label>

              <label>
                <input
                  type="radio"
                  name="frequency"
                  onChange={() => handleRadioChange("entirePage")}
                  checked={frequency === "entirePage"}
                />
                <span>
                  <span style={{ fontSize: "20px" }}>
                    Leave the size the same entire page
                  </span>
                </span>
              </label>
              <div>
                <Button
                  background="rovaOrange"
                  color="white"
                  onClick={() => setFirstStep(true)}
                >
                  <IoIosArrowBack
                    style={{
                      width: "2em",
                      height: "1.5em",
                      position: "absolute",
                      left: "10%",
                    }}
                  />
                  Back
                </Button>
                <Button
                  style={{ height: "41px", minHeight: "41px" }}
                  background="rovaOrange"
                  color="white"
                  onClick={applyChanges}
                >
                  Save
                </Button>
              </div>
            </FrequencyWrapper>
          </>
        )}
      </ExpandedFilter>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  preferences: state.readerReducer,
});

export const SizeFilter = connect(mapStateToProps, null)(SizeFilterComponent);
