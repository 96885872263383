import Snackbar from "@material-ui/core/Snackbar";
import React from "react";
import Typography from "@material-ui/core/Typography";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const Snack = ({ open, message, severity, handleClose }) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert severity={severity}>
        <Typography variant="body2" component="p">
          {message}
        </Typography>
      </Alert>
    </Snackbar>
  );
};
