import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  max-width: 100%;
  min-height: 900px;
  ${props =>
    props.background &&
    css`
      background-color: ${props => props.theme[props.background]};
    `};
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  overflow: scroll;
`;
/* background-color: #b4c7e7; */
