import {
  SELECT_BG_COLOR,
  SELECT_TEXT_COLOR,
  COLOR_FILTER,
  STYLE_FILTER,
  SIZE_FILTER,
  SHOW_FILTER,
  HIDE_FILTER,
  GET_PREFS,
  SELECT_BG_COLOR_ALPHA,
  SPACING_OPTION,
  SPACING_FILTER,
  LAST_FILTER,
  SAVE_STORY_PAGE,
  SHOW_FILTERS,
  SET_LINE_HEIGHT,
  SET_LETTER_SPACING,
  CLEAR,
} from "./types.js";

export const changeBackgroundColor = (color) => {
  return { type: SELECT_BG_COLOR, color };
};

export const changeBackgroundColorAlpha = (backgroundAlpha) => {
  return { type: SELECT_BG_COLOR_ALPHA, backgroundAlpha };
};

export const changeTextColor = (color) => {
  return { type: SELECT_TEXT_COLOR, color };
};

export const showFilter = (filter) => {
  return { type: SHOW_FILTER, filter };
};

export const hideFilter = (filter) => {
  return { type: HIDE_FILTER, filter };
};

export const changeSpacingOption = (option) => {
  return { type: SPACING_OPTION, option };
};

export const changeSpacing = (spacing) => {
  return { type: SPACING_FILTER, spacing };
};

export const changeColor = (colors) => {
  return { type: COLOR_FILTER, colors };
};
export const changeStyle = (styles) => {
  return {
    type: STYLE_FILTER,
    styles,
  };
};

export const changeSize = (sizes) => {
  return {
    type: SIZE_FILTER,
    sizes,
  };
};

export const getUserPrefs = (prefs) => {
  return {
    type: GET_PREFS,
    prefs,
  };
};

export const setLastFilter = (filter) => {
  return { type: LAST_FILTER, filter };
};

export const saveCurrentStoryPage = (prefs) => {
  return { type: SAVE_STORY_PAGE, prefs };
};

export const toggleFiltersVisibility = () => {
  return { type: SHOW_FILTERS };
};

export const clearReducer = () => {
  return { type: CLEAR };
};

export const changeLineHeight = (lineHeight) => {
  return { type: SET_LINE_HEIGHT, lineHeight };
};

export const changeLetterSpacing = (letterSpacing) => {
  return { type: SET_LETTER_SPACING, letterSpacing };
};
