import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
html, body, #root, #root>div {
  height: 100%;
  /* width: 100vw; */
  margin:0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Arial'
}

*{
  box-sizing: border-box;
}
`;
