import { combineReducers } from "redux";
import { userReducer } from "./userReducer";
import { readerReducer } from "./readerReducer";
import { history } from "../index.js";
import { LOGOUT } from "../actions";
import storage from "redux-persist/lib/storage";

const appReducer = combineReducers({
  userReducer: userReducer,
  readerReducer: readerReducer,
});

// export const rootReducer = combineReducers({ userReducer, readerReducer });

export const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    // for all keys defined in your persistConfig(s)
    storage.removeItem("persist:root");
    history.push("/");
    // storage.removeItem('persist:otherKey')

    state = undefined;
  }
  return appReducer(state, action);
};
