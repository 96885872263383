import styled from "styled-components";

export const CurrentUser = styled.div`
  margin: 0;
  padding: 0;
  display: flex;

  img {
    width: 53px;
    height: 53px;
    margin: auto;
    margin-right: 5px;
  }
  h1 {
    font-size: 18px;
    margin: auto;
  }
`;
