import styled, { css, keyframes } from "styled-components";
import { rgba } from "polished";
import Pagination from "@material-ui/lab/Pagination";
import Card from "@material-ui/core/Card";
import SettingsIcon from "@material-ui/icons/Settings";

export const StyledCharacter = styled.a`
  font-size: ${(props) => "" + props.size || 16}px;
  color: ${(props) => props.theme[props.color] || "black"};
  font-family: ${(props) => props.fontStyle};
  padding-right: ${(props) => props.padding || 0}px;
`;

export const StoryPageWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.rovaMain};
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  overflow-y: scroll;
  padding-bottom: 20px;
  margin-top: 66px;
  > div {
    margin-top: 66px;
  }
  & > svg {
    position: absolute;
    right: 2%;
    top: 10%;
    cursor: pointer;
  }
`;

export const StoryText = styled.div`
  background-color: ${(props) => props.theme[props.backgroundColor]};
  border-radius: 4px;
  border: none;
  width: 85%;
  height: fit-content;
  padding: 18px 24px;

  span {
    display: inline-block;
    &:first-line {
      line-height: 100% !important;
    }
    padding-right: ${(props) => props.rightPadding}px;
  }
  background-color: ${(props) =>
    props.backgroundAlpha || props.theme[props.backgroundColor]} !important;
  &:before {
    content: " ";
    margin-top: -${(props) => props.lineHeight / 2}px;
    display: block;
  }
`;

export const Filters = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  button {
    height: 41px;
    border-radius: 3px;
  }
`;

export const Filter = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 41px;
  border-radius: 4px;
  border: none;
  background-color: #fff;
  color: ${(props) => props.theme.blue};
  margin-bottom: 17px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;

export const ExpandedFilter = styled.div`
  width: 440px;
  border-radius: 4px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  & > h1 {
    width: 360px;
    margin: 23px 0;
    font-size: 16px;
    line-height: 18px;
  }
  & > div {
    width: 360px;
    margin: 0 auto;
  }
  /* button {
    height: 60px;
    width: 60px;
  } */
  & > button {
    width: 360px;
    margin: 0 auto;
    padding: 0;
    margin-top: 16px;
    margin-bottom: 30px;
  }
  & > span {
    margin: 0;
    padding: 0;
    width: 16px;
    height: 16px;
    align-self: flex-end;
    justify-self: flex-end;
    color: "#979797";
  }
`;

export const BgColorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  > button {
    margin-right: 11px;
  }
`;

export const BgColor = styled.button`
  display: flex;
  ${(props) =>
    props.background &&
    css`
      background-color: ${(props) => props.theme[props.background]};
    `};
  border: none;
  border-radius: 4px;
  width: 60px;
  height: 60px;
  &:active,
  &:focus {
    outline: white;
  }
  ${(props) =>
    props.checked &&
    css`
      &::before {
        font-family: "Font Awesome 5 Free";
        content: "\f058";
        font-weight: 900;
        color: white;
        font-size: 1.3em;
        height: 20px;
        width: 20px;
        padding-bottom: 1em;
      }
    `}
`;

export const TextColorWrapper = styled.div`
  display: grid;
  width: 100%;
  margin: 0;

  grid-gap: 19px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  align-items: center;
  justify-items: center;
  div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    button {
      height: 41px;
      width: 45%;
      border-radius: 3px;
      border: none;
      svg {
        vertical-align: middle;
      }
    }
  }
`;

export const TextColor = styled.button`
  display: flex;
  width: 103px;
  height: 87px;
  outline: white;
  border: none;
  border-radius: 4px;
  ${(props) =>
    props.background &&
    css`
      background-color: ${(props) => props.theme[props.background]};
    `};
  ${({ checked }) => checked && css``}
  ${(props) =>
    props.checked &&
    css`
      & {
        width: 90px !important;
        height: 75px !important;
      }
      &::before {
        font-family: "Font Awesome 5 Free";
        content: "\f058";
        font-weight: 900;
        color: white;
        font-size: 1.3em;
        height: 20px;
        width: 20px;
        padding-bottom: 2em;
      }
    `}
`;

export const FrequencyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: ${(props) => props.theme.rovaMain};
  justify-content: center;
  label {
    height: 30px;
    margin-bottom: 28px;
    display: inline-flex;
    align-items: center;
    width: 400px;
    letter-spacing: 1.7px;
    span {
      font-size: 13px;
      color: #333;
    }
  }
  input {
    margin-right: 13px;
  }
  input[type="radio"] {
    border: 1px solid #fff;
    padding-left: 0.1em;
    -webkit-appearance: none;
    border: 2px solid #5fc881;
    vertical-align: middle;
    min-width: 20px;
    width: 20px;
    min-height: 20px;
    height: 20px;
  }
  /* input[type="radio"]:checked {
    
  } */

  input[type="radio"]:checked {
    outline-color: transparent;
    background-color: #5fc881;
    &::before {
      font-family: "Font Awesome 5 Free";
      content: "\f00c";
      font-weight: 900;
      color: white;
      font-size: 1em;
    }
  }
  div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    button {
      height: 41px;
      width: 45%;
      border-radius: 3px;
      border: none;
      background-color: ${(props) => props.theme.rovaOrange};
      letter-spacing: 1px;
      font-size: 16px;
      svg {
        position: absolute;
        vertical-align: middle;
      }
    }
  }
`;

export const TextStyle = styled.div`
  height: 110px;
  width: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #5fc881;
  border-radius: 4px;
  cursor: pointer;
  font-size: 20px;
  letter-spacing: 0.75px;
  text-align: center;
  line-height: 100%;
  font-weight: 500;
  & > svg {
    position: relative;
    display: inline !important;
    align-self: flex-start !important;
    justify-self: flex-start !important;
    font-size: 20px !important;
  }
  ${(props) =>
    props.fontStyle &&
    props.fontFamily &&
    props.fontSize &&
    props.textColor &&
    css`
      font-style: ${(props) => props.fontStyle};
      font-family: ${(props) => props.fontFamily};
      font-size: ${(props) => props.fontSize};
      color: ${(props) => props.textColor};
    `};
`;

export const TextStyleGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 20px;
  > button {
    margin-right: 11px;
  }
`;

export const TextSizesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: ${(props) => props.theme.rovaGray};
  h1 {
    width: 360px;
  }
  label {
    height: 30px;
    margin-bottom: 28px;
  }
  div {
    height: 30px;
    margin-bottom: 37px;
  }
  input {
    margin-right: 13px;
    vertical-align: middle;
  }
  input[type="checkbox"] {
    border: 1px solid #fff;
    padding: 0.1em;
    -webkit-appearance: none;
    border: 2px solid #5fc881;
  }
  input[type="checkbox"]:checked {
    background-color: #5fc881;
    &::before {
      font-family: "Font Awesome 5 Free";
      content: "\f00c";
      font-weight: 900;
      color: white;
      font-size: 1.3em;
    }
  }

  input[type="checkbox"]:focus {
    outline-color: transparent;
  }
`;

export const ScrollBar = styled.div`
  height: 600px;
  width: 2px;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  svg {
    cursor: pointer;
    vertical-align: middle;
    circle {
      r: 12;
    }
  }
`;

export const ScrollBarVertical = styled.div`
  height: 2px;
  background-color: #fff;
  border-radius: 5px;
  position: absolute;
  width: 600px;
  display: flex;

  margin-top: 100px;
  svg {
    cursor: pointer;
    vertical-align: top;
    circle {
      r: 12;
    }
  }
`;

export const SpacingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: space-around;
  justify-content: center;
`;

export const BgColorAlpha = styled.div`
  height: 24px;
  margin-top: 18px !important;
  ${(props) =>
    props.backgroundColor &&
    css`
      background-image: linear-gradient(
        to right,
        ${(props) => props.theme[props.backgroundColor]},
        ${(props) => rgba(props.theme[props.backgroundColor] || "#000000", 0.5)}
      );
    `};

  svg {
    cursor: pointer;
  }
`;

export const SpacingOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: auto;
  height: 350px;
`;
export const SpacingOption = styled.button`
  width: 100% !important;
  border: none;
  border-radius: 2px;
  height: 48px;
  background-color: ${(props) => props.theme[props.background]};
  color: ${(props) => props.theme[props.color]};
  border: 2px solid ${(props) => props.theme.green};
  outline: none;
`;

export const StoryPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
`;

const fromRight = keyframes`
  from {
    right: -30%;
    transform: translateX(30%);
  }

  to {
    transform: translateX(0);
  }
`;

export const StyledCard = styled(Card)`
  position: absolute;
  right: 1%;
  width: 440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > svg {
    position: absolute;
    right: 1%;
    top: 0%;
    cursor: pointer;
  }
  animation: ${fromRight} 0.5s linear;
  animation-fill-mode: both;
`;

export const StyledGearIcon = styled(SettingsIcon)`
  color: white;
  animation: ${fromRight} 0.5s linear;
  animation-fill-mode: both;
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 25px !important;
  button {
    height: 41px;
    width: 45%;
    border-radius: 3px;
    border: none;
    svg {
      vertical-align: middle;
    }
    font-weight: 800;
    font-family: Arial-BoldMT;
    font-size: 14px;
  }
`;
