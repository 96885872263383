import styled from "styled-components";
import { Rectangle } from "../../Components/Rectangle";

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  height: 100px;
  margin-top: 35px;
  margin-bottom: 23px;
  box-sizing: border-box;
  * {
    cursor: pointer;
  }
  button {
    margin-right: 10px;
    width: 95px;
    height: 39px !important;
    min-height: 39px;
    border-radius: 3px;
    border: none;
    outline: none;
  }
  p {
    color: white;
    font-size: 18px;
    align-self: center;
    margin-top: 18px;
  }
`;

export const StoriesWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.rovaMain};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  > h1 {
    height: 35px;
    margin-top: 40px;
    padding-top: 40px;
    margin-bottom: 35px;
  }
`;

export const StoriesContainer = styled.div`
  display: grid;
  grid-gap: 48px;
  grid-template-rows: 233px;
  grid-template-columns: repeat(4, 233px);
  align-items: center;
  justify-items: center;
  justify-content: center;
  width: 100%;
  height: 700px;
  margin: auto;
`;

export const StoryRectangle = styled(Rectangle)`
  color: black;
  cursor: pointer;

  background-color: ${(props) => {
    const { level } = props;
    if (level === "1") {
      return "#c5e0b3";
    } else if (level === "2") {
      return "#fef0bb";
    } else if (level === "3") {
      return "#f4c9ba";
    } else if (level === "4") {
      return "#CE99F7";
    } else if (level === "5") {
      return "#83DCFF";
    } else {
      return "#c5e0b3";
    }
  }};
  border: 1px solid;
  border-color: ${(props) => {
    const { level } = props;
    if (level === "1") {
      return "#5fc881";
    } else if (level === "2") {
      return "#ffde17";
    } else if (level === "3") {
      return "#d8191f";
    } else if (level === "4") {
      return "#A67BC7";
    } else if (level === "5") {
      return "#68B0CC";
    } else {
      return "#c5e0b3";
    }
  }};
  width: 233px;
  height: 233px;
  h1 {
    margin: auto !important;
    align-self: flex-end;

    text-align: center;
    font-size: 18px;
  }
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    margin-top: 1em;
    margin-bottom: 1em;
    width: 120px;
    height: 120px;
  }
`;
