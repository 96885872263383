import * as firebase from "firebase/app";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ThemeProvider } from "styled-components";
import { theme } from "./styles/theme.js";
import { GlobalStyle } from "./styles/globalStyles.js";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import "firebase/auth";
import "./index.css";
import { saveUserToken } from "./actions/userActions.js";
import { axiosClient } from "./utils/axiosClient";

export const history = createBrowserHistory();

const Loading = () => <h1>Loading</h1>;

const project = process.env.REACT_APP_PROJECT;

const firebaseConfigDev = {
  apiKey: "AIzaSyBY0vZxaOesx5Mu4Ogbgudy9IMvyF8bTio",
  authDomain: "dev-visual-reader.firebaseapp.com",
  databaseURL: "https://dev-visual-reader.firebaseio.com",
  projectId: "dev-visual-reader",
  storageBucket: "dev-visual-reader.appspot.com",
  messagingSenderId: "652851852761",
  appId: "1:652851852761:web:4201c2b494840e005315c8",
  measurementId: "G-S2GFZWJWTW"
};
const firebaseConfigProd = {
  apiKey: "AIzaSyBKIWsgBYDXPQncgtugH0GZp3xxO7riogs",
  authDomain: "visual-reader-f6f55.firebaseapp.com",
  databaseURL: "https://visual-reader-f6f55.firebaseio.com",
  projectId: "visual-reader-f6f55",
  storageBucket: "visual-reader-f6f55.appspot.com",
  messagingSenderId: "463629407047",
  appId: "1:463629407047:web:a6480e7eae7f57a6107262",
  measurementId: "G-3YBZ3DS9PP"
}

const firebaseConfig = (project === 'dev-visual-reader') ? firebaseConfigDev : firebaseConfigProd;

firebase.initializeApp(firebaseConfig);

firebase.auth().onAuthStateChanged(function (user) {
  if (user) {
    user.getIdToken(true).then(function (token) {
      store.dispatch(saveUserToken(token));
      axiosClient.defaults.headers.common["token"] = token;
    });
  }
});

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<Loading />} persistor={persistor}>
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <App />

          <GlobalStyle />
        </ThemeProvider>
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
