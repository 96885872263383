import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { H1 } from "../../Components/H1";
import { Button } from "../../Components/Button";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ExpandedFilter,
  TextStyleGrid,
  TextStyle,
  FrequencyWrapper,
  ButtonsWrapper,
} from "./styles.js";

import { hideFilter, changeStyle } from "../../actions";
import { axiosClient } from "../../utils/axiosClient.js";

import { Progress } from "../../Components/Progress";

function StyleFilteromponent({ user, preferences }) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.userReducer.token);
  const [textStyles, setTextStyles] = useState([]);
  const [frequency, setFrequency] = useState("");
  const [firstStep, setFirstStep] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const fonts = [
    "Chiller",
    "Curlz MT",
    "'Bauhaus 93'",
    "Georgia",
    "Comic Sans MS",
    "Bradley Hand ITC Std",
    "Arial Black",
    "Ravie",
    "Century",
    "Bernard MT Condensed",
    "Harrington",
    "Times New Roman",
  ];
  const { styles } = preferences;
  useEffect(() => {
    if (styles.hasOwnProperty("selected")) {
      setTextStyles(styles.selected);
    }
    if (styles.hasOwnProperty("frequency")) {
      setFrequency(styles.frequency);
    }
  }, [styles]);

  async function applyChanges() {
    const { lastFilter, currentFilter, ...prefs } = preferences;
    prefs.username = user.username;
    prefs.avatar = user.avatar;
    prefs.styles.selected = textStyles;
    prefs.styles.frequency = frequency;
    setLoading(true);
    await axiosClient.put("users/setPreferences", prefs, {
      params: { user_id: user.id },
      headers: { token },
    });
    setLoading(false);
    dispatch(changeStyle({ textStyles, frequency }));
    closeFilter();
  }

  function handleRadioChange(freq) {
    setFrequency(freq);
    dispatch(changeStyle({ textStyles, frequency: freq }));
  }

  function handleTextStyle(style) {
    if (textStyles.length < 5) {
      if (textStyles.indexOf(style) > -1) {
        setTextStyles([
          ...textStyles.filter((textStyle) => textStyle !== style),
        ]);
      } else {
        setTextStyles([...textStyles, style]);
      }
    } else {
      setTextStyles([...textStyles.filter((textStyle) => textStyle !== style)]);
    }
  }

  function closeFilter() {
    dispatch(hideFilter());
  }

  async function handleNextButton() {
    if (textStyles.length === 1) {
      const { lastFilter, currentFilter, ...prefs } = preferences;
      prefs.username = user.username;
      prefs.avatar = user.avatar;
      setLoading(true);
      await axiosClient.put("users/setPreferences", prefs, {
        params: { user_id: user.id },
        headers: { token },
      });
      setLoading(false);
      dispatch(changeStyle({ textStyles, frequency: "entirePage" }));
      closeFilter();
    } else {
      setFirstStep(false);
    }
  }

  return (
    <>
      {isLoading && <Progress />}
      <ExpandedFilter>
        {firstStep ? (
          <>
            <H1 color="rovaGray" style={{ fontSize: "20px", margin: "15px 0" }}>
              Word Style
            </H1>
            <H1 color="rovaGray">Pick up to 5 word styles you like</H1>
            <TextStyleGrid>
              {fonts.map((font, idx) => (
                <TextStyle
                  key={idx}
                  style={{
                    backgroundColor: textStyles.includes(font)
                      ? "#5fc881"
                      : "white",
                    color: !textStyles.includes(font) ? "#5fc881" : "white",
                    fontFamily: font,
                    fontStyle: "normal",
                    fontSize: font ? "25px" : "20px",
                  }}
                  onClick={() => handleTextStyle(font)}
                >
                  {textStyles.includes(font) ? (
                    <FontAwesomeIcon icon="check-circle" />
                  ) : null}
                  <p
                    style={{
                      fontSize: "12px",
                      width: "80%",
                      lineHeight: "20px",
                    }}
                  >
                    Pick this font if you like it
                  </p>
                </TextStyle>
              ))}
            </TextStyleGrid>
            <ButtonsWrapper>
              <Button
                background="rovaOrange"
                color="white"
                onClick={closeFilter}
              >
                <IoIosArrowBack
                  style={{
                    width: "2em",
                    height: "1.5em",
                    position: "absolute",
                    left: "10%",
                  }}
                />
                Back
              </Button>
              <Button
                background="rovaOrange"
                color="white"
                style={{ fontSize: "14px", height: "41px" }}
                onClick={handleNextButton}
              >
                Next
                <IoIosArrowForward
                  style={{
                    width: "2em",
                    height: "1.5em",
                    position: "absolute",
                    right: "10%",
                  }}
                />
              </Button>
            </ButtonsWrapper>
          </>
        ) : (
          <>
            <H1 color="rovaGray" style={{ fontSize: "20px", margin: "15px 0" }}>
              Word Style
            </H1>
            <H1 color="rovaGray">Pick how often the style changes</H1>
            <FrequencyWrapper>
              <label>
                <input
                  type="radio"
                  name="frequency"
                  onChange={() => handleRadioChange("everyLetter")}
                  checked={frequency === "everyLetter"}
                />
                <span>
                  <span style={{ fontFamily: "Arial Black" }}>C</span>
                  <span style={{ fontFamily: "Georgia" }}>h</span>
                  <span style={{ fontFamily: "Bradley Hand ITC Std" }}>a</span>
                  <span style={{ fontFamily: "Comic Sans MS" }}>n</span>
                  <span style={{ fontFamily: "Century" }}>g</span>
                  <span style={{ fontFamily: "Ravie" }}>e</span>
                  <span> </span>
                  <span style={{ fontFamily: "Arial Black" }}>t</span>
                  <span style={{ fontFamily: "Georgia" }}>h</span>
                  <span style={{ fontFamily: "Bradley Hand ITC Std" }}>e</span>
                  <span> </span>
                  <span style={{ fontFamily: "Comic Sans MS" }}>s</span>
                  <span style={{ fontFamily: "Bradley Hand ITC Std" }}>t</span>
                  <span style={{ fontFamily: "Arial Black" }}>y</span>
                  <span style={{ fontFamily: "Georgia" }}>l</span>
                  <span style={{ fontFamily: "Bradley Hand ITC Std" }}>e</span>
                  <span> </span>
                  <span style={{ fontFamily: "Comic Sans MS" }}>e</span>
                  <span style={{ fontFamily: "Century" }}>v</span>
                  <span style={{ fontFamily: "Arial Black" }}>e</span>
                  <span style={{ fontFamily: "Georgia" }}>r</span>
                  <span style={{ fontFamily: "Bradley Hand ITC Std" }}>y</span>
                  <span> </span>
                  <span style={{ fontFamily: "Bradley Hand Bold" }}>l</span>
                  <span style={{ fontFamily: "Century" }}>e</span>
                  <span style={{ fontFamily: "Arial Black" }}>t</span>
                  <span style={{ fontFamily: "Georgia" }}>t</span>
                  <span style={{ fontFamily: "Ravie" }}>e</span>
                  <span style={{ fontFamily: "Comic Sans MS" }}>r</span>
                </span>
              </label>
              <label>
                <input
                  type="radio"
                  name="frequency"
                  onChange={() => handleRadioChange("everyWord")}
                  checked={frequency === "everyWord"}
                />
                <span>
                  <span style={{ fontFamily: "Ravie" }}>Change</span>
                  <span> </span>
                  <span style={{ fontFamily: "Arial" }}>the</span>
                  <span> </span>
                  <span style={{ fontFamily: "Comic Sans MS" }}>style</span>
                  <span> </span>
                  <span style={{ fontFamily: "Arial Black" }}>every</span>
                  <span> </span>
                  <span style={{ fontFamily: "Century" }}>word</span>
                </span>
              </label>

              <label>
                <input
                  type="radio"
                  name="frequency"
                  onChange={() => handleRadioChange("everyFourWords")}
                  checked={frequency === "everyFourWords"}
                />
                <span>
                  <span style={{ fontFamily: "Ravie" }}>
                    Change the style every
                  </span>
                  <span> </span>
                  <span style={{ fontFamily: "Arial Black" }}>
                    four words to have
                  </span>
                  <span> </span>
                  <span style={{ fontFamily: "Comic Sans MS" }}>
                    it look like this
                  </span>
                </span>
              </label>

              <label>
                <input
                  type="radio"
                  name="frequency"
                  onChange={() => handleRadioChange("everySentence")}
                  checked={frequency === "everySentence"}
                />
                <span>
                  <span style={{ fontFamily: "Century" }}>
                    Change the color style every sentence to look like this.
                  </span>
                  <span> </span>
                  <span style={{ fontFamily: "Arial Black" }}>
                    How does this look?
                  </span>
                </span>
              </label>

              <label>
                <input
                  type="radio"
                  name="frequency"
                  onChange={() => handleRadioChange("entirePage")}
                  checked={frequency === "entirePage"}
                />
                <span style={{ fontFamily: "Century" }}>
                  Change the style every sentence to look like this. How does
                  this look
                </span>
              </label>
              <div>
                <Button
                  background="rovaOrange"
                  color="white"
                  onClick={() => setFirstStep(true)}
                >
                  <IoIosArrowBack
                    style={{
                      width: "2em",
                      height: "1.5em",
                      position: "absolute",
                      left: "10%",
                    }}
                  />
                  Back
                </Button>
                <Button
                  background="rovaOrange"
                  color="white"
                  onClick={applyChanges}
                >
                  Save
                </Button>
              </div>
            </FrequencyWrapper>
          </>
        )}
      </ExpandedFilter>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  preferences: state.readerReducer,
});

export const StyleFilter = connect(mapStateToProps, null)(StyleFilteromponent);
