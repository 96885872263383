import {
  GET_USER,
  ALL_USERS,
  SAVE_USER,
  CURRENT_USER,
  ALL_STORIES,
  FILTER_STORIES,
  SHOW_ALL,
  CURRENT_STORY,
  LOGOUT,
  SAVE_USER_TOKEN,
} from "./types.js";
import { axiosClient } from "../utils/axiosClient.js";

export const getUser = () => {
  return { type: GET_USER };
};

export const SaveUser = (user) => {
  return { type: SAVE_USER, user };
};

export function setUser(user) {
  return (dispatch) => {
    return dispatch({
      type: SAVE_USER,
      user,
    });
  };
}

export const allUsers = (users) => {
  return { type: ALL_USERS, users };
};

export const fetchUsers = (token) => {
  return (dispatch) => {
    return axiosClient.get("/users", { headers: { token } }).then((res) => {
      dispatch({
        type: ALL_USERS,
        users: Object.keys(res.data).map((key) => {
          let ar = res.data[key];
          ar.id = key;
          return ar;
        }),
      });
    });
  };
};

export const currentUser = (user) => {
  return (dispatch) => {
    return dispatch({
      type: CURRENT_USER,
      user: user,
    });
  };
};

export const fetchStories = (token) => {
  return (dispatch) => {
    return axiosClient.get("/stories", { headers: { token } }).then((res) => {
      dispatch({
        type: ALL_STORIES,
        stories: res.data,
        initialStories: res.data,
      });
    });
  };
};

export const filterStories = (level) => {
  return (dispatch) => {
    return dispatch({ type: FILTER_STORIES, level });
  };
};

export const showAllStories = (initialStories) => {
  return (dispatch) => {
    return dispatch({
      type: SHOW_ALL,
      stories: initialStories,
      level: "ALL",
    });
  };
};

export const selectCurrentStory = (story) => {
  return (dispatch) => {
    return dispatch({
      type: CURRENT_STORY,
      story,
    });
  };
};

export const logout = () => {
  return (dispatch) => {
    return dispatch({
      type: LOGOUT,
    });
  };
};

export const saveUserToken = (token) => {
  return (dispatch) => {
    return dispatch({
      type: SAVE_USER_TOKEN,
      token,
    });
  };
};
