import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Wrapper } from "../../Components/Wrapper";
import { Header } from "../../Components/Header";
import {
  StoryPageWrapper,
  StoryText,
  Filter,
  StyledCharacter,
  ScrollBar,
  ScrollBarVertical,
  StoryPagination,
  StyledCard,
} from "./styles";
import { stylingFunction, randomNoRepeats } from "./StylingFunction.js";
import {
  showFilter,
  saveCurrentStoryPage,
  toggleFiltersVisibility,
} from "../../actions/";
import { getUserPrefs } from "../../actions";
import { StyleFilter } from "./styleFilter.js";
import { ColorFilter } from "./colorFilter.js";
import { SizeFilter } from "./sizeFilter";
import { SpacingFilter } from "./SpacingFilter";
import { MdFiberManualRecord } from "react-icons/md";
import { IconContext } from "react-icons";
import Draggable from "react-draggable";

import CloseIcon from "@material-ui/icons/Close";
// import SettingsIcon from "@material-ui/icons/Settings";
import {
  setLastFilter,
  changeLineHeight,
  changeLetterSpacing,
} from "../../actions";

import { Progress } from "../../Components/Progress";
import { axiosClient } from "../../utils/axiosClient";
function StoryComponent({
  story,
  colors,
  currentFilter,
  styles,
  sizes,
  backgroundAlpha,
  spacing,
  lastFilter,
  user,
  preferences,
  showFilters,
}) {
  const fonts = styles;
  const DEFAULT_LETTER_SPACING = 2;
  const DEFAULT_LINE_HEIGHT = 20;
  const dispatch = useDispatch();
  const [lineHeight, setLineHeight] = useState(DEFAULT_LINE_HEIGHT);
  const [letterSpacing, setLetterSpacing] = useState(DEFAULT_LETTER_SPACING);
  const [currentStory, setCurrentStory] = useState("");
  const [storyPages, setStoryPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setLoading] = useState(true);
  const [backgroundColor, setBackgroundColor] = useState("");

  const token = useSelector((state) => state.userReducer.token);
  const storyID = story.id;

  function getStoryPage() {
    setStoryPages(story.pages);
    localStorage.clear();
    setLoading(true);
    let page;
    if (preferences.hasOwnProperty("library")) {
      const currentStoryIndex = preferences.library.findIndex(
        (story) => story.story === storyID
      );
      if (currentStoryIndex >= 0) {
        page = preferences.library[currentStoryIndex].page;
      }
    }
    if (page === undefined) {
      page = 1;
    }
    axiosClient
      .get("/stories/story/" + story.id + "?page=" + page, {
        headers: { token },
      })
      .then((res) => {
        setCurrentStory(res.data.replace(/  +/g, " "));
        setLoading(false);
      });
  }

  function saveStoryPage(page) {
    const prefs = Object.assign({}, preferences);
    if (prefs.hasOwnProperty("library")) {
      const currentStoryIndex = prefs.library.findIndex(
        (story) => story.story === storyID
      );
      if (currentStoryIndex >= 0) {
        prefs.library[currentStoryIndex].page = page;
      } else {
        prefs.library.push({ story: story.id, page: page });
      }
    } else {
      prefs.library = [];
      prefs.library.push({ story: story.id, page: page });
    }

    axiosClient.put("users/setPreferences", prefs, {
      params: { user_id: user },
      headers: { token },
    });
    dispatch(saveCurrentStoryPage(prefs));
  }

  useEffect(() => {
    setBackgroundColor(backgroundAlpha);
    if (
      spacing.hasOwnProperty("lineHeight") &&
      lineHeight === DEFAULT_LINE_HEIGHT
    ) {
      setLineHeight(spacing.lineHeight);
    }
    if (
      spacing.hasOwnProperty("letterSpacing") &&
      letterSpacing === DEFAULT_LETTER_SPACING
    ) {
      setLetterSpacing(spacing.letterSpacing);
    }

    if (preferences.hasOwnProperty("library")) {
      const currentStoryIndex = preferences.library.findIndex(
        (story) => story.story === storyID
      );
      if (currentStoryIndex >= 0) {
        setCurrentPage(preferences.library[currentStoryIndex].page);
      }
    }
  }, [
    backgroundAlpha,
    spacing,
    preferences,
    storyID,
    currentFilter,
    lastFilter,
    letterSpacing,
    lineHeight,
  ]);

  async function getMySettings() {
    setLoading(true);
    await axiosClient
      .get("/users/myPreferencesByID/", { params: { user_id: user } })
      .then((res) => {
        dispatch(getUserPrefs(res.data));
      });
  }

  useEffect(() => {
    if (lastFilter !== "BGALPHA") {
      localStorage.clear();
    }
    if (currentFilter) {
      localStorage.clear();
    }
    getStoryPage();
  }, [currentPage]);

  useEffect(() => {
    getMySettings();
  }, []);

  function expandFilter(filter) {
    dispatch(showFilter(filter));
  }

  function handlePagination(e, page) {
    document
      .getElementById("0")
      .scrollIntoView({ block: "end", inline: "nearest" });
    setCurrentPage(page);

    saveStoryPage(page);
  }

  function toggleSettingsVisibility() {
    dispatch(toggleFiltersVisibility());
  }

  function renderFilter(currentFilter) {
    switch (currentFilter) {
      case "color":
        return <ColorFilter />;
      case "style":
        return <StyleFilter />;
      case "size":
        return <SizeFilter />;
      case "spacing":
        return (
          <SpacingFilter
            letterSpacing={letterSpacing}
            lineHeight={lineHeight}
          />
        );
      default:
        return (
          <>
            <CloseIcon onClick={toggleSettingsVisibility} />
            <Filter onClick={() => expandFilter("color")}>Colors</Filter>
            <Filter onClick={() => expandFilter("style")}>Word Style</Filter>
            <Filter onClick={() => expandFilter("size")}>Word Size</Filter>
            <Filter onClick={() => expandFilter("spacing")}>Spacing</Filter>
          </>
        );
    }
  }

  const charOptions = {
    colors,
    fonts,
    sizes,
  };
  const characterData = stylingFunction(
    currentStory,

    charOptions
  );
  const paragraphs = `${currentStory}\n`.match(/[^\n]+[\n]+/g);
  const wordMap = {};
  const sentenceMap = {};
  let sentenceCount = 0;
  let paragraphsLength = paragraphs ? paragraphs.length : 0;
  for (let i = 0; i < paragraphsLength; i++) {
    const p = paragraphs[i];
    let wordCount = 0;
    for (let j = 0; j < p.length; j++) {
      if (
        colors.frequency === "everyWord" &&
        [".", ",", "?", "!"].includes(p[j])
      ) {
        wordCount++;
      }
      wordMap[[i, j]] = wordCount;
      sentenceMap[[i, j]] = sentenceCount;
      if (p[j] === " " && j > 0) {
        wordCount++;
      }
      if (
        [".", "\n", "?", "!"].includes(p[j]) &&
        j > 0 &&
        (j === p.length - 1 || p[j + 1] !== "”")
      ) {
        sentenceCount++;
      }
    }
    sentenceCount++;
  }

  const styledStory = characterData.map((char, idx) => (
    <span key={idx}>
      {char.map((styles, index) => {
        if (styles[0] === " ") {
          return (
            <span
              key={index}
              style={{
                paddingLeft:
                  spacing.spacingOption === "word"
                    ? Math.max(letterSpacing / 10, 2) + "px"
                    : null,
              }}
            >
              {" "}
            </span>
          );
        } else if (
          styles[0] === "." ||
          styles[0] === "?" ||
          styles[0] === "!" ||
          styles[0] === "↵" ||
          styles[0] === "\n"
        ) {
          return (
            <StyledCharacter
              id={index === 0 ? "0" : null}
              key={index}
              fontStyle={
                fonts.frequency === "entirePage" ? fonts.selected[0] : styles[1]
              }
              style={{
                paddingRight:
                  spacing.spacingOption === "sentence"
                    ? letterSpacing + "px"
                    : null,
              }}
              size={
                sizes.frequency === "entirePage" ? sizes.selected[0] : styles[3]
              }
              color={
                colors.frequency === "entirePage"
                  ? colors.selected[0]
                  : randomNoRepeats(
                      colors.frequency,
                      colors.selected,
                      idx,
                      sentenceMap[[idx, index]],
                      wordMap[[idx, index]],
                      index
                    )
              }
            >
              {index === 0 ? <br /> : ""}
              {styles[0]}
              {index < char.length - 1 && char[index + 1][0] === " " ? " " : ""}
            </StyledCharacter>
          );
        } else {
          return (
            <StyledCharacter
              id={index === 0 ? "0" : null}
              key={index}
              fontStyle={
                fonts.frequency === "entirePage" ? fonts.selected[0] : styles[1]
              }
              color={
                colors.frequency === "entirePage"
                  ? colors.selected[0]
                  : randomNoRepeats(
                      colors.frequency,
                      colors.selected,
                      idx,
                      sentenceMap[[idx, index]],
                      wordMap[[idx, index]],
                      index
                    )
              }
              size={
                sizes.frequency === "entirePage" ? sizes.selected[0] : styles[3]
              }
            >
              {index === 0 ? <br /> : ""}
              {styles[0]}
              {index < char.length - 1 && char[index + 1][0] === " " ? " " : ""}
            </StyledCharacter>
          );
        }
      })}
    </span>
  ));

  function handleLineHeigh(e, x) {
    dispatch(setLastFilter({ filter: "BGALPHA" }));

    dispatch(changeLineHeight({ lineHeight: x.y }));
    setLineHeight(x.y);
  }

  function handleLetterSpacing(e, x) {
    dispatch(setLastFilter({ filter: "BGALPHA" }));

    dispatch(changeLetterSpacing({ letterSpacing: x.x / 10 }));
    setLetterSpacing(x.x);
  }

  return (
    <>
      {isLoading && <Progress />}
      <Wrapper>
        <Header />
        <StoryPageWrapper>
          {currentFilter === "spacing" ? (
            <ScrollBar>
              <Draggable
                axis="y"
                handle=".handle"
                defaultPosition={{ x: 0, y: 0 }}
                position={{ x: 0, y: lineHeight }}
                grid={[5, 5]}
                scale={1}
                onDrag={handleLineHeigh}
                bounds={{ top: 0, bottom: 600 }}
              >
                <div className="handle">
                  <IconContext.Provider
                    value={{ color: "#f0592b", size: "26px" }}
                  >
                    <MdFiberManualRecord />
                  </IconContext.Provider>
                </div>
              </Draggable>
            </ScrollBar>
          ) : null}
          {currentFilter === "spacing" ? (
            <ScrollBarVertical style={{ marginTop: "38px" }}>
              <Draggable
                axis="x"
                handle=".handle"
                defaultPosition={{ x: 0, y: 0 }}
                position={{ x: letterSpacing, y: 0 }}
                grid={[5, 5]}
                scale={1}
                onDrag={handleLetterSpacing}
                bounds={{ left: 0, right: 580 }}
              >
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="handle"
                >
                  <IconContext.Provider
                    value={{ color: "#f0592b", size: "26px" }}
                  >
                    <MdFiberManualRecord />
                  </IconContext.Provider>
                </div>
              </Draggable>
            </ScrollBarVertical>
          ) : null}
          <StoryText
            backgroundAlpha={backgroundColor}
            style={{
              color:
                colors.frequency === "entirePage"
                  ? colors.selected[0] + "!important"
                  : "black",
              fontSize:
                sizes.frequency === "entirePage"
                  ? sizes.selected[0] + "px !important"
                  : "",
              lineHeight: lineHeight >= 20 ? lineHeight + "px" : "20px",
              paddingTop: "0",
            }}
            lineHeight={lineHeight}
          >
            <p>{styledStory}</p>
            <StoryPagination
              page={currentPage}
              count={storyPages}
              onChange={handlePagination}
            />
          </StoryText>
          {showFilters && (
            <StyledCard>{renderFilter(currentFilter)}</StyledCard>
          )}
        </StoryPageWrapper>
      </Wrapper>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    story: state.userReducer.story,
    currentFilter: state.readerReducer.currentFilter,
    colors: state.readerReducer.colors,
    bgColor: state.readerReducer.colors.backgroundColor,
    styles: state.readerReducer.styles,
    alpha: state.readerReducer.colors.alpha,
    sizes: state.readerReducer.sizes,
    user: state.userReducer.user.id,
    preferences: state.readerReducer,
    backgroundAlpha: state.readerReducer.colors.backgroundAlpha,
    spacing: state.readerReducer.spacing,
    lastFilter: state.readerReducer.lastFilter.filter,
    showFilters: state.readerReducer.showFilters,
  };
};

export const Story = connect(mapStateToProps, null)(StoryComponent);
