import styled, { css } from "styled-components";

export const Button = styled.button`
  ${(props) =>
    props.background &&
    css`
      background-color: ${(props) => props.theme[props.background]};
    `};
  ${(props) =>
    props.border &&
    css`
      border-color: ${(props) => props.theme[props.border]};
    `};
  ${(props) =>
    props.color &&
    css`
      color: ${(props) => props.theme[props.color]};
    `};
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  border: none;
`;
